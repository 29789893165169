.link {
    color: blue;
    cursor: pointer;
}

@keyframes cdp-in {
    from {
        transform: scale(1.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}
.cdp {
    position: relative;
    text-align: center;
    padding: 20px 0;
    font-size: 0;
    z-index: 6;
    margin: 50px 0;
    animation: cdp-in 500ms ease both;
    animation-timeout: 200ms;
}
.cdp_i {
    font-size: 14px;
    text-decoration: none;
    transition: background 250ms;
    display: inline-block;
    text-transform: uppercase;
    margin: 0 3px 6px;
    height: 38px;
    min-width: 38px;
    border-radius: 38px;
    border: 1px solid #fff;
    line-height: 38px;
    padding: 0;
    color: #fff;
    font-weight: 700;
    letter-spacing: .03em;
    display: none;
}
.cdp_i:first-child, .cdp_i:last-child {
    padding: 0 16px;
    margin: 0 12px 6px;
}
.cdp_i:last-child, .cdp_i:nth-child(2), .cdp_i:nth-last-child(2) {
    display: inline-block;
}
.cdp_i:hover {
    background-color: #1a1a1a;
    color: #fff;
}
.cdp:not([actpage="1"]) .cdp_i:nth-child(1) {
    display: inline-block;
}

.cdp[actpage="1"] .cdp_i:nth-child(-1):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="1"] .cdp_i:nth-child(-1):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="1"] .cdp_i:nth-child(0):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="1"] .cdp_i:nth-child(1):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="1"] .cdp_i:nth-child(2) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="1"] .cdp_i:nth-child(2) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="1"] .cdp_i:nth-child(3):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="1"] .cdp_i:nth-child(4):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="1"] .cdp_i:nth-child(5):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="1"] .cdp_i:nth-child(5):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="2"] .cdp_i:nth-child(0):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="2"] .cdp_i:nth-child(0):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="2"] .cdp_i:nth-child(1):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="2"] .cdp_i:nth-child(2):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="2"] .cdp_i:nth-child(3) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="2"] .cdp_i:nth-child(3) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="2"] .cdp_i:nth-child(4):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="2"] .cdp_i:nth-child(5):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="2"] .cdp_i:nth-child(6):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="2"] .cdp_i:nth-child(6):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="3"] .cdp_i:nth-child(1):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="3"] .cdp_i:nth-child(1):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="3"] .cdp_i:nth-child(2):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="3"] .cdp_i:nth-child(3):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="3"] .cdp_i:nth-child(4) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="3"] .cdp_i:nth-child(4) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="3"] .cdp_i:nth-child(5):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="3"] .cdp_i:nth-child(6):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="3"] .cdp_i:nth-child(7):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="3"] .cdp_i:nth-child(7):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="4"] .cdp_i:nth-child(2):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="4"] .cdp_i:nth-child(2):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="4"] .cdp_i:nth-child(3):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="4"] .cdp_i:nth-child(4):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="4"] .cdp_i:nth-child(5) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="4"] .cdp_i:nth-child(5) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="4"] .cdp_i:nth-child(6):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="4"] .cdp_i:nth-child(7):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="4"] .cdp_i:nth-child(8):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="4"] .cdp_i:nth-child(8):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="5"] .cdp_i:nth-child(3):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="5"] .cdp_i:nth-child(3):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="5"] .cdp_i:nth-child(4):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="5"] .cdp_i:nth-child(5):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="5"] .cdp_i:nth-child(6) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="5"] .cdp_i:nth-child(6) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="5"] .cdp_i:nth-child(7):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="5"] .cdp_i:nth-child(8):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="5"] .cdp_i:nth-child(9):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="5"] .cdp_i:nth-child(9):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="6"] .cdp_i:nth-child(4):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="6"] .cdp_i:nth-child(4):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="6"] .cdp_i:nth-child(5):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="6"] .cdp_i:nth-child(6):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="6"] .cdp_i:nth-child(7) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="6"] .cdp_i:nth-child(7) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="6"] .cdp_i:nth-child(8):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="6"] .cdp_i:nth-child(9):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="6"] .cdp_i:nth-child(10):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="6"] .cdp_i:nth-child(10):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="7"] .cdp_i:nth-child(5):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="7"] .cdp_i:nth-child(5):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="7"] .cdp_i:nth-child(6):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="7"] .cdp_i:nth-child(7):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="7"] .cdp_i:nth-child(8) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="7"] .cdp_i:nth-child(8) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="7"] .cdp_i:nth-child(9):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="7"] .cdp_i:nth-child(10):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="7"] .cdp_i:nth-child(11):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="7"] .cdp_i:nth-child(11):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="8"] .cdp_i:nth-child(6):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="8"] .cdp_i:nth-child(6):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="8"] .cdp_i:nth-child(7):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="8"] .cdp_i:nth-child(8):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="8"] .cdp_i:nth-child(9) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="8"] .cdp_i:nth-child(9) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="8"] .cdp_i:nth-child(10):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="8"] .cdp_i:nth-child(11):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="8"] .cdp_i:nth-child(12):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="8"] .cdp_i:nth-child(12):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="9"] .cdp_i:nth-child(7):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="9"] .cdp_i:nth-child(7):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="9"] .cdp_i:nth-child(8):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="9"] .cdp_i:nth-child(9):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="9"] .cdp_i:nth-child(10) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="9"] .cdp_i:nth-child(10) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="9"] .cdp_i:nth-child(11):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="9"] .cdp_i:nth-child(12):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="9"] .cdp_i:nth-child(13):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="9"] .cdp_i:nth-child(13):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="10"] .cdp_i:nth-child(8):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="10"] .cdp_i:nth-child(8):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="10"] .cdp_i:nth-child(9):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="10"] .cdp_i:nth-child(10):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="10"] .cdp_i:nth-child(11) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="10"] .cdp_i:nth-child(11) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="10"] .cdp_i:nth-child(12):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="10"] .cdp_i:nth-child(13):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="10"] .cdp_i:nth-child(14):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="10"] .cdp_i:nth-child(14):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="11"] .cdp_i:nth-child(9):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="11"] .cdp_i:nth-child(9):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="11"] .cdp_i:nth-child(10):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="11"] .cdp_i:nth-child(11):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="11"] .cdp_i:nth-child(12) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="11"] .cdp_i:nth-child(12) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="11"] .cdp_i:nth-child(13):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="11"] .cdp_i:nth-child(14):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="11"] .cdp_i:nth-child(15):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="11"] .cdp_i:nth-child(15):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="12"] .cdp_i:nth-child(10):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="12"] .cdp_i:nth-child(10):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="12"] .cdp_i:nth-child(11):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="12"] .cdp_i:nth-child(12):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="12"] .cdp_i:nth-child(13) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="12"] .cdp_i:nth-child(13) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="12"] .cdp_i:nth-child(14):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="12"] .cdp_i:nth-child(15):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="12"] .cdp_i:nth-child(16):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="12"] .cdp_i:nth-child(16):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="13"] .cdp_i:nth-child(11):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="13"] .cdp_i:nth-child(11):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="13"] .cdp_i:nth-child(12):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="13"] .cdp_i:nth-child(13):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="13"] .cdp_i:nth-child(14) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="13"] .cdp_i:nth-child(14) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="13"] .cdp_i:nth-child(15):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="13"] .cdp_i:nth-child(16):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="13"] .cdp_i:nth-child(17):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="13"] .cdp_i:nth-child(17):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="14"] .cdp_i:nth-child(12):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="14"] .cdp_i:nth-child(12):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="14"] .cdp_i:nth-child(13):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="14"] .cdp_i:nth-child(14):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="14"] .cdp_i:nth-child(15) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="14"] .cdp_i:nth-child(15) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="14"] .cdp_i:nth-child(16):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="14"] .cdp_i:nth-child(17):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="14"] .cdp_i:nth-child(18):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="14"] .cdp_i:nth-child(18):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="15"] .cdp_i:nth-child(13):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="15"] .cdp_i:nth-child(13):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="15"] .cdp_i:nth-child(14):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="15"] .cdp_i:nth-child(15):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="15"] .cdp_i:nth-child(16) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="15"] .cdp_i:nth-child(16) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="15"] .cdp_i:nth-child(17):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="15"] .cdp_i:nth-child(18):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="15"] .cdp_i:nth-child(19):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="15"] .cdp_i:nth-child(19):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="16"] .cdp_i:nth-child(14):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="16"] .cdp_i:nth-child(14):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="16"] .cdp_i:nth-child(15):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="16"] .cdp_i:nth-child(16):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="16"] .cdp_i:nth-child(17) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="16"] .cdp_i:nth-child(17) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="16"] .cdp_i:nth-child(18):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="16"] .cdp_i:nth-child(19):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="16"] .cdp_i:nth-child(20):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="16"] .cdp_i:nth-child(20):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="17"] .cdp_i:nth-child(15):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="17"] .cdp_i:nth-child(15):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="17"] .cdp_i:nth-child(16):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="17"] .cdp_i:nth-child(17):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="17"] .cdp_i:nth-child(18) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="17"] .cdp_i:nth-child(18) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="17"] .cdp_i:nth-child(19):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="17"] .cdp_i:nth-child(20):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="17"] .cdp_i:nth-child(21):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="17"] .cdp_i:nth-child(21):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="18"] .cdp_i:nth-child(16):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="18"] .cdp_i:nth-child(16):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="18"] .cdp_i:nth-child(17):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="18"] .cdp_i:nth-child(18):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="18"] .cdp_i:nth-child(19) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="18"] .cdp_i:nth-child(19) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="18"] .cdp_i:nth-child(20):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="18"] .cdp_i:nth-child(21):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="18"] .cdp_i:nth-child(22):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="18"] .cdp_i:nth-child(22):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="19"] .cdp_i:nth-child(17):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="19"] .cdp_i:nth-child(17):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="19"] .cdp_i:nth-child(18):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="19"] .cdp_i:nth-child(19):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="19"] .cdp_i:nth-child(20) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="19"] .cdp_i:nth-child(20) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="19"] .cdp_i:nth-child(21):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="19"] .cdp_i:nth-child(22):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="19"] .cdp_i:nth-child(23):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="19"] .cdp_i:nth-child(23):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="20"] .cdp_i:nth-child(18):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="20"] .cdp_i:nth-child(18):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="20"] .cdp_i:nth-child(19):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="20"] .cdp_i:nth-child(20):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="20"] .cdp_i:nth-child(21) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="20"] .cdp_i:nth-child(21) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="20"] .cdp_i:nth-child(22):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="20"] .cdp_i:nth-child(23):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="20"] .cdp_i:nth-child(24):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="20"] .cdp_i:nth-child(24):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="21"] .cdp_i:nth-child(19):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="21"] .cdp_i:nth-child(19):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="21"] .cdp_i:nth-child(20):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="21"] .cdp_i:nth-child(21):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="21"] .cdp_i:nth-child(22) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="21"] .cdp_i:nth-child(22) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="21"] .cdp_i:nth-child(23):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="21"] .cdp_i:nth-child(24):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="21"] .cdp_i:nth-child(25):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="21"] .cdp_i:nth-child(25):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="22"] .cdp_i:nth-child(20):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="22"] .cdp_i:nth-child(20):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="22"] .cdp_i:nth-child(21):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="22"] .cdp_i:nth-child(22):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="22"] .cdp_i:nth-child(23) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="22"] .cdp_i:nth-child(23) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="22"] .cdp_i:nth-child(24):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="22"] .cdp_i:nth-child(25):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="22"] .cdp_i:nth-child(26):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="22"] .cdp_i:nth-child(26):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="23"] .cdp_i:nth-child(21):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="23"] .cdp_i:nth-child(21):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="23"] .cdp_i:nth-child(22):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="23"] .cdp_i:nth-child(23):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="23"] .cdp_i:nth-child(24) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="23"] .cdp_i:nth-child(24) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="23"] .cdp_i:nth-child(25):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="23"] .cdp_i:nth-child(26):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="23"] .cdp_i:nth-child(27):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="23"] .cdp_i:nth-child(27):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="24"] .cdp_i:nth-child(22):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="24"] .cdp_i:nth-child(22):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="24"] .cdp_i:nth-child(23):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="24"] .cdp_i:nth-child(24):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="24"] .cdp_i:nth-child(25) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="24"] .cdp_i:nth-child(25) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="24"] .cdp_i:nth-child(26):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="24"] .cdp_i:nth-child(27):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="24"] .cdp_i:nth-child(28):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="24"] .cdp_i:nth-child(28):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="25"] .cdp_i:nth-child(23):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="25"] .cdp_i:nth-child(23):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="25"] .cdp_i:nth-child(24):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="25"] .cdp_i:nth-child(25):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="25"] .cdp_i:nth-child(26) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="25"] .cdp_i:nth-child(26) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="25"] .cdp_i:nth-child(27):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="25"] .cdp_i:nth-child(28):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="25"] .cdp_i:nth-child(29):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="25"] .cdp_i:nth-child(29):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="26"] .cdp_i:nth-child(24):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="26"] .cdp_i:nth-child(24):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="26"] .cdp_i:nth-child(25):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="26"] .cdp_i:nth-child(26):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="26"] .cdp_i:nth-child(27) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="26"] .cdp_i:nth-child(27) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="26"] .cdp_i:nth-child(28):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="26"] .cdp_i:nth-child(29):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="26"] .cdp_i:nth-child(30):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="26"] .cdp_i:nth-child(30):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="27"] .cdp_i:nth-child(25):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="27"] .cdp_i:nth-child(25):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="27"] .cdp_i:nth-child(26):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="27"] .cdp_i:nth-child(27):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="27"] .cdp_i:nth-child(28) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="27"] .cdp_i:nth-child(28) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="27"] .cdp_i:nth-child(29):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="27"] .cdp_i:nth-child(30):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="27"] .cdp_i:nth-child(31):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="27"] .cdp_i:nth-child(31):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="28"] .cdp_i:nth-child(26):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="28"] .cdp_i:nth-child(26):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="28"] .cdp_i:nth-child(27):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="28"] .cdp_i:nth-child(28):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="28"] .cdp_i:nth-child(29) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="28"] .cdp_i:nth-child(29) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="28"] .cdp_i:nth-child(30):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="28"] .cdp_i:nth-child(31):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="28"] .cdp_i:nth-child(32):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="28"] .cdp_i:nth-child(32):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="29"] .cdp_i:nth-child(27):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="29"] .cdp_i:nth-child(27):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="29"] .cdp_i:nth-child(28):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="29"] .cdp_i:nth-child(29):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="29"] .cdp_i:nth-child(30) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="29"] .cdp_i:nth-child(30) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="29"] .cdp_i:nth-child(31):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="29"] .cdp_i:nth-child(32):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="29"] .cdp_i:nth-child(33):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="29"] .cdp_i:nth-child(33):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="30"] .cdp_i:nth-child(28):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="30"] .cdp_i:nth-child(28):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="30"] .cdp_i:nth-child(29):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="30"] .cdp_i:nth-child(30):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="30"] .cdp_i:nth-child(31) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="30"] .cdp_i:nth-child(31) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="30"] .cdp_i:nth-child(32):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="30"] .cdp_i:nth-child(33):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="30"] .cdp_i:nth-child(34):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="30"] .cdp_i:nth-child(34):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="31"] .cdp_i:nth-child(29):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="31"] .cdp_i:nth-child(29):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="31"] .cdp_i:nth-child(30):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="31"] .cdp_i:nth-child(31):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="31"] .cdp_i:nth-child(32) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="31"] .cdp_i:nth-child(32) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="31"] .cdp_i:nth-child(33):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="31"] .cdp_i:nth-child(34):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="31"] .cdp_i:nth-child(35):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="31"] .cdp_i:nth-child(35):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="32"] .cdp_i:nth-child(30):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="32"] .cdp_i:nth-child(30):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="32"] .cdp_i:nth-child(31):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="32"] .cdp_i:nth-child(32):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="32"] .cdp_i:nth-child(33) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="32"] .cdp_i:nth-child(33) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="32"] .cdp_i:nth-child(34):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="32"] .cdp_i:nth-child(35):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="32"] .cdp_i:nth-child(36):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="32"] .cdp_i:nth-child(36):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="33"] .cdp_i:nth-child(31):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="33"] .cdp_i:nth-child(31):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="33"] .cdp_i:nth-child(32):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="33"] .cdp_i:nth-child(33):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="33"] .cdp_i:nth-child(34) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="33"] .cdp_i:nth-child(34) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="33"] .cdp_i:nth-child(35):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="33"] .cdp_i:nth-child(36):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="33"] .cdp_i:nth-child(37):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="33"] .cdp_i:nth-child(37):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="34"] .cdp_i:nth-child(32):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="34"] .cdp_i:nth-child(32):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="34"] .cdp_i:nth-child(33):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="34"] .cdp_i:nth-child(34):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="34"] .cdp_i:nth-child(35) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="34"] .cdp_i:nth-child(35) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="34"] .cdp_i:nth-child(36):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="34"] .cdp_i:nth-child(37):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="34"] .cdp_i:nth-child(38):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="34"] .cdp_i:nth-child(38):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="35"] .cdp_i:nth-child(33):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="35"] .cdp_i:nth-child(33):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="35"] .cdp_i:nth-child(34):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="35"] .cdp_i:nth-child(35):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="35"] .cdp_i:nth-child(36) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="35"] .cdp_i:nth-child(36) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="35"] .cdp_i:nth-child(37):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="35"] .cdp_i:nth-child(38):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="35"] .cdp_i:nth-child(39):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="35"] .cdp_i:nth-child(39):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="36"] .cdp_i:nth-child(34):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="36"] .cdp_i:nth-child(34):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="36"] .cdp_i:nth-child(35):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="36"] .cdp_i:nth-child(36):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="36"] .cdp_i:nth-child(37) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="36"] .cdp_i:nth-child(37) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="36"] .cdp_i:nth-child(38):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="36"] .cdp_i:nth-child(39):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="36"] .cdp_i:nth-child(40):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="36"] .cdp_i:nth-child(40):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="37"] .cdp_i:nth-child(35):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="37"] .cdp_i:nth-child(35):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="37"] .cdp_i:nth-child(36):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="37"] .cdp_i:nth-child(37):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="37"] .cdp_i:nth-child(38) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="37"] .cdp_i:nth-child(38) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="37"] .cdp_i:nth-child(39):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="37"] .cdp_i:nth-child(40):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="37"] .cdp_i:nth-child(41):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="37"] .cdp_i:nth-child(41):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="38"] .cdp_i:nth-child(36):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="38"] .cdp_i:nth-child(36):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="38"] .cdp_i:nth-child(37):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="38"] .cdp_i:nth-child(38):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="38"] .cdp_i:nth-child(39) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="38"] .cdp_i:nth-child(39) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="38"] .cdp_i:nth-child(40):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="38"] .cdp_i:nth-child(41):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="38"] .cdp_i:nth-child(42):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="38"] .cdp_i:nth-child(42):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="39"] .cdp_i:nth-child(37):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="39"] .cdp_i:nth-child(37):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="39"] .cdp_i:nth-child(38):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="39"] .cdp_i:nth-child(39):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="39"] .cdp_i:nth-child(40) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="39"] .cdp_i:nth-child(40) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="39"] .cdp_i:nth-child(41):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="39"] .cdp_i:nth-child(42):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="39"] .cdp_i:nth-child(43):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="39"] .cdp_i:nth-child(43):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="40"] .cdp_i:nth-child(38):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="40"] .cdp_i:nth-child(38):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="40"] .cdp_i:nth-child(39):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="40"] .cdp_i:nth-child(40):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="40"] .cdp_i:nth-child(41) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="40"] .cdp_i:nth-child(41) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="40"] .cdp_i:nth-child(42):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="40"] .cdp_i:nth-child(43):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="40"] .cdp_i:nth-child(44):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="40"] .cdp_i:nth-child(44):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="41"] .cdp_i:nth-child(39):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="41"] .cdp_i:nth-child(39):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="41"] .cdp_i:nth-child(40):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="41"] .cdp_i:nth-child(41):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="41"] .cdp_i:nth-child(42) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="41"] .cdp_i:nth-child(42) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="41"] .cdp_i:nth-child(43):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="41"] .cdp_i:nth-child(44):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="41"] .cdp_i:nth-child(45):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="41"] .cdp_i:nth-child(45):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="42"] .cdp_i:nth-child(40):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="42"] .cdp_i:nth-child(40):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="42"] .cdp_i:nth-child(41):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="42"] .cdp_i:nth-child(42):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="42"] .cdp_i:nth-child(43) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="42"] .cdp_i:nth-child(43) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="42"] .cdp_i:nth-child(44):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="42"] .cdp_i:nth-child(45):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="42"] .cdp_i:nth-child(46):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="42"] .cdp_i:nth-child(46):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="43"] .cdp_i:nth-child(41):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="43"] .cdp_i:nth-child(41):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="43"] .cdp_i:nth-child(42):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="43"] .cdp_i:nth-child(43):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="43"] .cdp_i:nth-child(44) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="43"] .cdp_i:nth-child(44) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="43"] .cdp_i:nth-child(45):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="43"] .cdp_i:nth-child(46):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="43"] .cdp_i:nth-child(47):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="43"] .cdp_i:nth-child(47):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="44"] .cdp_i:nth-child(42):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="44"] .cdp_i:nth-child(42):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="44"] .cdp_i:nth-child(43):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="44"] .cdp_i:nth-child(44):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="44"] .cdp_i:nth-child(45) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="44"] .cdp_i:nth-child(45) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="44"] .cdp_i:nth-child(46):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="44"] .cdp_i:nth-child(47):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="44"] .cdp_i:nth-child(48):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="44"] .cdp_i:nth-child(48):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="45"] .cdp_i:nth-child(43):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="45"] .cdp_i:nth-child(43):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="45"] .cdp_i:nth-child(44):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="45"] .cdp_i:nth-child(45):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="45"] .cdp_i:nth-child(46) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="45"] .cdp_i:nth-child(46) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="45"] .cdp_i:nth-child(47):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="45"] .cdp_i:nth-child(48):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="45"] .cdp_i:nth-child(49):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="45"] .cdp_i:nth-child(49):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="46"] .cdp_i:nth-child(44):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="46"] .cdp_i:nth-child(44):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="46"] .cdp_i:nth-child(45):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="46"] .cdp_i:nth-child(46):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="46"] .cdp_i:nth-child(47) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="46"] .cdp_i:nth-child(47) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="46"] .cdp_i:nth-child(48):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="46"] .cdp_i:nth-child(49):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="46"] .cdp_i:nth-child(50):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="46"] .cdp_i:nth-child(50):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="47"] .cdp_i:nth-child(45):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="47"] .cdp_i:nth-child(45):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="47"] .cdp_i:nth-child(46):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="47"] .cdp_i:nth-child(47):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="47"] .cdp_i:nth-child(48) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="47"] .cdp_i:nth-child(48) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="47"] .cdp_i:nth-child(49):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="47"] .cdp_i:nth-child(50):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="47"] .cdp_i:nth-child(51):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="47"] .cdp_i:nth-child(51):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="48"] .cdp_i:nth-child(46):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="48"] .cdp_i:nth-child(46):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="48"] .cdp_i:nth-child(47):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="48"] .cdp_i:nth-child(48):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="48"] .cdp_i:nth-child(49) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="48"] .cdp_i:nth-child(49) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="48"] .cdp_i:nth-child(50):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="48"] .cdp_i:nth-child(51):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="48"] .cdp_i:nth-child(52):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="48"] .cdp_i:nth-child(52):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="49"] .cdp_i:nth-child(47):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="49"] .cdp_i:nth-child(47):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="49"] .cdp_i:nth-child(48):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="49"] .cdp_i:nth-child(49):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="49"] .cdp_i:nth-child(50) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="49"] .cdp_i:nth-child(50) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="49"] .cdp_i:nth-child(51):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="49"] .cdp_i:nth-child(52):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="49"] .cdp_i:nth-child(53):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="49"] .cdp_i:nth-child(53):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="50"] .cdp_i:nth-child(48):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="50"] .cdp_i:nth-child(48):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="50"] .cdp_i:nth-child(49):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="50"] .cdp_i:nth-child(50):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="50"] .cdp_i:nth-child(51) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="50"] .cdp_i:nth-child(51) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="50"] .cdp_i:nth-child(52):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="50"] .cdp_i:nth-child(53):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="50"] .cdp_i:nth-child(54):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="50"] .cdp_i:nth-child(54):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="51"] .cdp_i:nth-child(49):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="51"] .cdp_i:nth-child(49):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="51"] .cdp_i:nth-child(50):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="51"] .cdp_i:nth-child(51):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="51"] .cdp_i:nth-child(52) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="51"] .cdp_i:nth-child(52) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="51"] .cdp_i:nth-child(53):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="51"] .cdp_i:nth-child(54):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="51"] .cdp_i:nth-child(55):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="51"] .cdp_i:nth-child(55):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="52"] .cdp_i:nth-child(50):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="52"] .cdp_i:nth-child(50):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="52"] .cdp_i:nth-child(51):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="52"] .cdp_i:nth-child(52):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="52"] .cdp_i:nth-child(53) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="52"] .cdp_i:nth-child(53) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="52"] .cdp_i:nth-child(54):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="52"] .cdp_i:nth-child(55):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="52"] .cdp_i:nth-child(56):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="52"] .cdp_i:nth-child(56):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="53"] .cdp_i:nth-child(51):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="53"] .cdp_i:nth-child(51):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="53"] .cdp_i:nth-child(52):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="53"] .cdp_i:nth-child(53):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="53"] .cdp_i:nth-child(54) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="53"] .cdp_i:nth-child(54) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="53"] .cdp_i:nth-child(55):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="53"] .cdp_i:nth-child(56):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="53"] .cdp_i:nth-child(57):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="53"] .cdp_i:nth-child(57):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="54"] .cdp_i:nth-child(52):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="54"] .cdp_i:nth-child(52):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="54"] .cdp_i:nth-child(53):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="54"] .cdp_i:nth-child(54):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="54"] .cdp_i:nth-child(55) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="54"] .cdp_i:nth-child(55) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="54"] .cdp_i:nth-child(56):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="54"] .cdp_i:nth-child(57):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="54"] .cdp_i:nth-child(58):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="54"] .cdp_i:nth-child(58):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="55"] .cdp_i:nth-child(53):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="55"] .cdp_i:nth-child(53):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="55"] .cdp_i:nth-child(54):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="55"] .cdp_i:nth-child(55):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="55"] .cdp_i:nth-child(56) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="55"] .cdp_i:nth-child(56) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="55"] .cdp_i:nth-child(57):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="55"] .cdp_i:nth-child(58):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="55"] .cdp_i:nth-child(59):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="55"] .cdp_i:nth-child(59):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="56"] .cdp_i:nth-child(54):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="56"] .cdp_i:nth-child(54):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="56"] .cdp_i:nth-child(55):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="56"] .cdp_i:nth-child(56):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="56"] .cdp_i:nth-child(57) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="56"] .cdp_i:nth-child(57) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="56"] .cdp_i:nth-child(58):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="56"] .cdp_i:nth-child(59):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="56"] .cdp_i:nth-child(60):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="56"] .cdp_i:nth-child(60):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="57"] .cdp_i:nth-child(55):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="57"] .cdp_i:nth-child(55):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="57"] .cdp_i:nth-child(56):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="57"] .cdp_i:nth-child(57):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="57"] .cdp_i:nth-child(58) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="57"] .cdp_i:nth-child(58) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="57"] .cdp_i:nth-child(59):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="57"] .cdp_i:nth-child(60):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="57"] .cdp_i:nth-child(61):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="57"] .cdp_i:nth-child(61):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="58"] .cdp_i:nth-child(56):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="58"] .cdp_i:nth-child(56):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="58"] .cdp_i:nth-child(57):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="58"] .cdp_i:nth-child(58):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="58"] .cdp_i:nth-child(59) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="58"] .cdp_i:nth-child(59) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="58"] .cdp_i:nth-child(60):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="58"] .cdp_i:nth-child(61):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="58"] .cdp_i:nth-child(62):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="58"] .cdp_i:nth-child(62):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="59"] .cdp_i:nth-child(57):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="59"] .cdp_i:nth-child(57):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="59"] .cdp_i:nth-child(58):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="59"] .cdp_i:nth-child(59):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="59"] .cdp_i:nth-child(60) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="59"] .cdp_i:nth-child(60) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="59"] .cdp_i:nth-child(61):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="59"] .cdp_i:nth-child(62):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="59"] .cdp_i:nth-child(63):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="59"] .cdp_i:nth-child(63):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="60"] .cdp_i:nth-child(58):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="60"] .cdp_i:nth-child(58):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="60"] .cdp_i:nth-child(59):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="60"] .cdp_i:nth-child(60):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="60"] .cdp_i:nth-child(61) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="60"] .cdp_i:nth-child(61) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="60"] .cdp_i:nth-child(62):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="60"] .cdp_i:nth-child(63):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="60"] .cdp_i:nth-child(64):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="60"] .cdp_i:nth-child(64):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="61"] .cdp_i:nth-child(59):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="61"] .cdp_i:nth-child(59):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="61"] .cdp_i:nth-child(60):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="61"] .cdp_i:nth-child(61):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="61"] .cdp_i:nth-child(62) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="61"] .cdp_i:nth-child(62) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="61"] .cdp_i:nth-child(63):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="61"] .cdp_i:nth-child(64):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="61"] .cdp_i:nth-child(65):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="61"] .cdp_i:nth-child(65):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="62"] .cdp_i:nth-child(60):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="62"] .cdp_i:nth-child(60):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="62"] .cdp_i:nth-child(61):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="62"] .cdp_i:nth-child(62):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="62"] .cdp_i:nth-child(63) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="62"] .cdp_i:nth-child(63) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="62"] .cdp_i:nth-child(64):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="62"] .cdp_i:nth-child(65):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="62"] .cdp_i:nth-child(66):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="62"] .cdp_i:nth-child(66):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="63"] .cdp_i:nth-child(61):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="63"] .cdp_i:nth-child(61):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="63"] .cdp_i:nth-child(62):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="63"] .cdp_i:nth-child(63):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="63"] .cdp_i:nth-child(64) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="63"] .cdp_i:nth-child(64) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="63"] .cdp_i:nth-child(65):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="63"] .cdp_i:nth-child(66):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="63"] .cdp_i:nth-child(67):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="63"] .cdp_i:nth-child(67):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="64"] .cdp_i:nth-child(62):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="64"] .cdp_i:nth-child(62):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="64"] .cdp_i:nth-child(63):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="64"] .cdp_i:nth-child(64):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="64"] .cdp_i:nth-child(65) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="64"] .cdp_i:nth-child(65) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="64"] .cdp_i:nth-child(66):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="64"] .cdp_i:nth-child(67):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="64"] .cdp_i:nth-child(68):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="64"] .cdp_i:nth-child(68):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="65"] .cdp_i:nth-child(63):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="65"] .cdp_i:nth-child(63):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="65"] .cdp_i:nth-child(64):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="65"] .cdp_i:nth-child(65):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="65"] .cdp_i:nth-child(66) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="65"] .cdp_i:nth-child(66) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="65"] .cdp_i:nth-child(67):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="65"] .cdp_i:nth-child(68):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="65"] .cdp_i:nth-child(69):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="65"] .cdp_i:nth-child(69):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="66"] .cdp_i:nth-child(64):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="66"] .cdp_i:nth-child(64):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="66"] .cdp_i:nth-child(65):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="66"] .cdp_i:nth-child(66):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="66"] .cdp_i:nth-child(67) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="66"] .cdp_i:nth-child(67) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="66"] .cdp_i:nth-child(68):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="66"] .cdp_i:nth-child(69):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="66"] .cdp_i:nth-child(70):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="66"] .cdp_i:nth-child(70):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="67"] .cdp_i:nth-child(65):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="67"] .cdp_i:nth-child(65):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="67"] .cdp_i:nth-child(66):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="67"] .cdp_i:nth-child(67):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="67"] .cdp_i:nth-child(68) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="67"] .cdp_i:nth-child(68) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="67"] .cdp_i:nth-child(69):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="67"] .cdp_i:nth-child(70):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="67"] .cdp_i:nth-child(71):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="67"] .cdp_i:nth-child(71):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="68"] .cdp_i:nth-child(66):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="68"] .cdp_i:nth-child(66):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="68"] .cdp_i:nth-child(67):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="68"] .cdp_i:nth-child(68):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="68"] .cdp_i:nth-child(69) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="68"] .cdp_i:nth-child(69) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="68"] .cdp_i:nth-child(70):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="68"] .cdp_i:nth-child(71):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="68"] .cdp_i:nth-child(72):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="68"] .cdp_i:nth-child(72):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="69"] .cdp_i:nth-child(67):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="69"] .cdp_i:nth-child(67):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="69"] .cdp_i:nth-child(68):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="69"] .cdp_i:nth-child(69):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="69"] .cdp_i:nth-child(70) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="69"] .cdp_i:nth-child(70) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="69"] .cdp_i:nth-child(71):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="69"] .cdp_i:nth-child(72):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="69"] .cdp_i:nth-child(73):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="69"] .cdp_i:nth-child(73):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="70"] .cdp_i:nth-child(68):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="70"] .cdp_i:nth-child(68):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="70"] .cdp_i:nth-child(69):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="70"] .cdp_i:nth-child(70):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="70"] .cdp_i:nth-child(71) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="70"] .cdp_i:nth-child(71) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="70"] .cdp_i:nth-child(72):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="70"] .cdp_i:nth-child(73):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="70"] .cdp_i:nth-child(74):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="70"] .cdp_i:nth-child(74):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="71"] .cdp_i:nth-child(69):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="71"] .cdp_i:nth-child(69):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="71"] .cdp_i:nth-child(70):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="71"] .cdp_i:nth-child(71):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="71"] .cdp_i:nth-child(72) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="71"] .cdp_i:nth-child(72) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="71"] .cdp_i:nth-child(73):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="71"] .cdp_i:nth-child(74):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="71"] .cdp_i:nth-child(75):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="71"] .cdp_i:nth-child(75):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="72"] .cdp_i:nth-child(70):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="72"] .cdp_i:nth-child(70):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="72"] .cdp_i:nth-child(71):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="72"] .cdp_i:nth-child(72):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="72"] .cdp_i:nth-child(73) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="72"] .cdp_i:nth-child(73) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="72"] .cdp_i:nth-child(74):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="72"] .cdp_i:nth-child(75):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="72"] .cdp_i:nth-child(76):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="72"] .cdp_i:nth-child(76):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="73"] .cdp_i:nth-child(71):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="73"] .cdp_i:nth-child(71):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="73"] .cdp_i:nth-child(72):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="73"] .cdp_i:nth-child(73):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="73"] .cdp_i:nth-child(74) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="73"] .cdp_i:nth-child(74) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="73"] .cdp_i:nth-child(75):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="73"] .cdp_i:nth-child(76):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="73"] .cdp_i:nth-child(77):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="73"] .cdp_i:nth-child(77):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="74"] .cdp_i:nth-child(72):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="74"] .cdp_i:nth-child(72):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="74"] .cdp_i:nth-child(73):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="74"] .cdp_i:nth-child(74):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="74"] .cdp_i:nth-child(75) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="74"] .cdp_i:nth-child(75) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="74"] .cdp_i:nth-child(76):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="74"] .cdp_i:nth-child(77):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="74"] .cdp_i:nth-child(78):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="74"] .cdp_i:nth-child(78):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="75"] .cdp_i:nth-child(73):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="75"] .cdp_i:nth-child(73):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="75"] .cdp_i:nth-child(74):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="75"] .cdp_i:nth-child(75):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="75"] .cdp_i:nth-child(76) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="75"] .cdp_i:nth-child(76) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="75"] .cdp_i:nth-child(77):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="75"] .cdp_i:nth-child(78):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="75"] .cdp_i:nth-child(79):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="75"] .cdp_i:nth-child(79):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="76"] .cdp_i:nth-child(74):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="76"] .cdp_i:nth-child(74):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="76"] .cdp_i:nth-child(75):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="76"] .cdp_i:nth-child(76):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="76"] .cdp_i:nth-child(77) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="76"] .cdp_i:nth-child(77) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="76"] .cdp_i:nth-child(78):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="76"] .cdp_i:nth-child(79):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="76"] .cdp_i:nth-child(80):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="76"] .cdp_i:nth-child(80):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="77"] .cdp_i:nth-child(75):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="77"] .cdp_i:nth-child(75):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="77"] .cdp_i:nth-child(76):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="77"] .cdp_i:nth-child(77):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="77"] .cdp_i:nth-child(78) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="77"] .cdp_i:nth-child(78) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="77"] .cdp_i:nth-child(79):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="77"] .cdp_i:nth-child(80):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="77"] .cdp_i:nth-child(81):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="77"] .cdp_i:nth-child(81):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="78"] .cdp_i:nth-child(76):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="78"] .cdp_i:nth-child(76):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="78"] .cdp_i:nth-child(77):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="78"] .cdp_i:nth-child(78):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="78"] .cdp_i:nth-child(79) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="78"] .cdp_i:nth-child(79) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="78"] .cdp_i:nth-child(80):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="78"] .cdp_i:nth-child(81):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="78"] .cdp_i:nth-child(82):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="78"] .cdp_i:nth-child(82):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="79"] .cdp_i:nth-child(77):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="79"] .cdp_i:nth-child(77):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="79"] .cdp_i:nth-child(78):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="79"] .cdp_i:nth-child(79):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="79"] .cdp_i:nth-child(80) {
    background-color: #000;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="79"] .cdp_i:nth-child(80) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="79"] .cdp_i:nth-child(81):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="79"] .cdp_i:nth-child(82):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="79"] .cdp_i:nth-child(83):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="79"] .cdp_i:nth-child(83):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}

.cdp[actpage="80"] .cdp_i:nth-child(78):not(:first-child):not(:nth-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="80"] .cdp_i:nth-child(78):not(:first-child):not(:nth-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}
.cdp[actpage="80"] .cdp_i:nth-child(79):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="80"] .cdp_i:nth-child(80):not(:first-child) {
    display: inline-block;
}
.cdp[actpage="80"] .cdp_i:nth-child(81) {
    background-color: #1a1a1a;
    color: #fff;
    display: inline-block;
}
.cdp[actpage="80"] .cdp_i:nth-child(81) + .cdp_i:last-child {
    display: none !important;
}
.cdp[actpage="80"] .cdp_i:nth-child(82):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="80"] .cdp_i:nth-child(83):not(:last-child) {
    display: inline-block;
}
.cdp[actpage="80"] .cdp_i:nth-child(84):not(:last-child):not(:nth-last-child(2)) {
    display: inline-block;
    pointer-events: none;
    color: transparent;
    border-color: transparent;
    width: 50px;
}
.cdp[actpage="80"] .cdp_i:nth-child(84):not(:last-child):not(:nth-last-child(2)):after {
    content: '...';
    color: #fff;
    font-size: 32px;
    margin-left: -6px;
}