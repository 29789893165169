.page {

}

.owl-carousel .owl-item img {
  display: inherit !important;
  width: auto !important;
}

.goods .goods__item-text {
  padding-top: 5px;
}


// Общие

.row {
  margin: 0 !important;
  padding: 0 !important;
}

.logo img {
  min-width: 80px;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.black__button {
  padding: 15px 30px;
  background-color: #1a1a1a;
  color: #ffffff;
  font-weight: bold;
  text-align: center;
}

.separator {
  padding: 15px;
}

.breadcrumbs {
  margin: 20px auto;
  width: 80%;
  a, span {
    color: #5d5b5b !important;
    font-size: 12px !important;
  }
}

.equal, .equal > .slider__bottom-item, .equal > .goods__item, .equal > .projects__item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

body {
  background-color: #ededed;
  padding: 0;
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
}

a {
  text-decoration: none;
}

// Шапка

.header__inner {
  height: 80px;
  a {
    color: #ffffff;
    text-decoration: none;
    font-size: 14px;
  }
}

.header {
  background-color: #1a1a1a;
  ul {
    margin: 0;
    padding: 4px;
    text-align: right;
  }
  ul li {
    display: inline;
    margin-right: 30px;
    @media screen and (max-width: 1280px) {
      margin-right: 10px;
    }
    padding: 3px;
    a{
      text-transform: uppercase;
    }
  }

  form {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 42px;
  }

  input {
    color: #ffffff;
    height: 42px;
    width: 0;
    padding: 0 42px 0 15px;
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
    background: transparent;
    transition: .4s cubic-bezier(0, 0.8, 0, 1);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
  }

  input:focus {
    width: 300px;
    z-index: 1;
    border-bottom: 2px solid #F9F0DA;
  }

  button {
    background: #1a1a1a;
    border: none;
    height: 42px;
    width: 42px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  button:before {
    content: "";
    background: url("/img/search.png") no-repeat center center;
    display: block;
    width: 30px;
    height: 30px;
  }
}

* {
  box-sizing: border-box;
}

// Подвал

.footer {
  background-color: #1a1a1a;
  font-size: 13px;
  ul {
    margin: 0;
    padding: 4px;
    text-align: right;
  }
  ul li {
    display: inline;
    margin-right: 17px;
    @media screen and (max-width: 1280px) {
      margin-right: 10px;
    }
    padding: 3px;
    a{
      text-transform: uppercase;
    }
  }
  .footer__inner {
    height: 60px;
    a {
      color: #adadad;
      text-decoration: none;
      font-size: 13px;
      @media screen and (max-width: 1280px) {
        font-size: 10px;
      }
    }
  }
  p {
    color: #adadad;
  }
}

// Главная

.slider {
  background-image: url('/img/slider.jpg');
  background-position: top center;
  background-size: cover;
  position: relative;
  height: 100%;
  //margin-bottom: 120px;
  h1 {
    font-size: 3vw;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
  }
  .slider__title {
    padding: 5% 14%;
    //margin-bottom: 5%;
  }
  .slider__button {
    padding: 15px 28px;
    background-color: #1a1a1a;
    color: #ffffff;
    font-weight: bold;
    text-align: center;
  }
  .slider__block-button {
    text-align: center;
  }
  .slider__bottom {
    margin-top: 10% !important;
    //position: absolute;
    //bottom: -100px;
    @media screen and (max-width: 1280px) {
      //bottom: -118px;
    }
  }
  .slider__bottom-item {
    //margin: 10px;
    text-align: center;
    margin-bottom: 0;
  }
  .slider__bottom-img {
    background-color: #1a1a1a;
    height: 60%;
    justify-content: center;
    img {
      padding: 20px;
      max-width: 100%;
    }
  }
  .slider__bottom-text {
    background-color: #ffffff;
    min-height: 40%;
    @media screen and (max-width: 1280px) {
      padding-bottom: 30px;
    }
  }
  .slider__bottom-title {
    font-weight: bold;
  }
}

.actions {
  margin-top: 10px;
  .actions__block {
    margin: 25px 15px 0px;
    padding: 100px 0px 0px 0px;
  }
  .ab-1 {
    background-image: url('/img/action-1.png');
    background-position: bottom;
  }
  .ab-2 {
    background-image: url('/img/action-2.png');
    background-position: center;
  }
  .ab-3 {
    background-image: url('/img/action-3.png');
    background-position: center;
  }
  .actions__title {
    color: #ffffff;
    font-weight: bold;
    background: #1a1a1a;
    padding: 4px;
  }

  .actions__button {
    padding: 10px;
    background-color: #ffffff;
    font-weight: bold;
    color: #1a1a1a;
    white-space: nowrap;
    @media screen and (max-width: 1280px) {
      font-size: 13px;
    }
  }
}

.goods__main .goods__item {
  //margin: 10px;
}

.goods__main .goods__item {
  background: #ffffff;
}

.goods {

  .goods__item {
    margin-top: 20px;
    //background: #fff;
    .row {
      background: #ffffff;
    }
  }

  .goods__item-name a {
    color: #ffffff;
    @media screen and (max-width: 1622px) {
      font-size: 11px;
    }
  }


  .goods__item-img {
    background-color: #ffffff;
    text-align: center;
    padding: 20px 0;
    //height: 65%;
    height: 200px;
    img {
      max-width: 100%;
      padding: 10px;
    }
  }

  .goods__item-text {
    background-color: #1a1a1a;
    color: #ffffff;
    //height: 35%;
    height: 150px;
    .goods__item-price {
      text-align: right;
      font-weight: bold;
    }
  }
}

.intro {
  background-image: url('/img/intro.png');
  text-align: center;
  padding: 40px 10px;
  margin-top: 30px;
  .intro__item-img {
    margin-bottom: 20px;
  }
  h1 {
    color: #ffffff;
    font-size: 50px;
    padding-bottom: 40px;
  }
  p {
    color: #ffffff;
    margin: 0px 20px;
  }
}

.projects {
  margin-top: 30px;
  margin-bottom: 30px;
  .col-xs-five {
    width: 100%;
    margin-bottom: 20px;
  }
  .projects__item {
    margin-bottom: 20px;
  }
  .projects__item-img {
    background-position: center;
    background-size: cover;
    height: 200px;
    padding: 0;
  }
  .projects__item-text {
    height: 10%;
    background-color: #ffffff;
    text-align: left;
    font-weight: bold;
  }
  .projects__item-date {
    color: #888888;
    font-size: 10px;
  }
  .projects__item-name {
    font-size: 14px;
  }
  .projects__item-name a {
    color: #1a1a1a;
  }
  .projects__item-desc {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.7em;
  }
  .projects__item-first {
    background-color: #ffffff;
    text-align: center;
    //padding: 0;
    margin-bottom: 20px;
    //margin-left: 15px;
    //margin-right: 15px;
    h1 {
      font-size: 2vw;
    }
    a {
      font-size: 12px;
      padding: 7px 15px;
      border: 1px solid #1a1a1a;
      color: #1a1a1a;
    }
  }
}

.order {
  margin-top: 70px;
  margin-bottom: 70px;
  text-align: center;
}

// Все страницы

.files, .portfolio, .page__title, .filials, .catalog__inner, .vcenter, .search-page {
  margin: 0 auto;
  width: 80%;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.files__item {
  //a[href$=".doc"],
  //a[href$=".docx"],
  //a[href$=".rtf"] {
  //  background: url("/img/files-doc.png");
  //
  //}
  //
  //a[href$=".pdf"] {
  //  background: url("/img/files-pdf.png");
  //}
  //
  //a[href$=".jpg"],
  //a[href$=".JPG"],
  //a[href$=".png"],
  //a[href$=".jpeg"] {
  //  background: url("/img/files-jpg.png");
  //}
}


.page__title  {
  text-align: center;
  padding: 40px;
  h1 {
    font-size: 53px;
    text-transform: uppercase;
  }
  h2 {
    font-size: 37px;
    text-transform: uppercase;
  }
  p {
    font-weight: bold;
    font-size: 20px;
  }
}

p.upper {
  font-weight: normal;
  font-size: 25px;
  text-align: left;
}

p.upper__light {
  font-weight: normal;
  font-size: 17px;
  text-align: left;
}

.text-center {
  text-align: center !important;
}

// Страница поиска

.search-form {
  form {
    position: relative;
    max-width: 500px;
    margin: 0 auto;
  }
  input {
    width: 100%;
    height: 42px;
    padding-left: 10px;
    border: 1px solid #bbbbbb;
    border-radius: 0;
    outline: none;
    background: #ffffff;
    color: #9E9C9C;
  }
  button {
    position: absolute;
    top: 0;
    right: 0px;
    width: 80px;
    height: 42px;
    border: none;
    background: #1a1a1a;
    border-radius: 00;
    cursor: pointer;
  }
  button:before {
    content: "Найти";
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    width: 50px;
  }
}

.articles {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  .articles__name {
    font-weight: bold;
  }
  .articles__list {
    text-align: left;
  }
}

// 404

.nfound {
  text-align: center;
  font-size: 20vw;
    display: flex;
    height: 700px;

  span, img {
    margin: auto;
  }
  img {
    max-width: 100%;
  }
}

// Информация

.files {
  margin-bottom: 50px;
  .files__item {
    padding: 30px;
  }
  img {
    float: left;
    padding-right: 20px;
  }
  a {
    text-decoration: underline;
    color: #1a1a1a;
  }
}

// Контакты

.filials {
  .filials__item {
    margin-bottom: 30px;
    .row {
      height: 100%;
      background: #ffffff;
    }
    .filials__item-top {
      background-color: #1a1a1a;
      color: #ffffff;
      padding: 10px;
      height: 20%;
    }
    .filials__item-bottom {
      background-color: #ffffff;
    }
  }
}

// Портфолио

.portfolio {
  .projects__item {
    margin-bottom: 30px;
  }
}

// Каталог

.catalog {
  //height: 500px;
  .slider__title {
    padding: 2% 14%;
  }
  .slider__bottom {
    margin-top: 0 !important;
  }
}

.catalog__block .goods__item {
  margin: 0 0 30px 0;
}

.catalog__dop_item {
  background-color: #ffffff;
  margin: 10px;
  p {
    font-weight: bold;
  }
}

.catalog__item {
  h1 {
    padding-top: 0;
    margin-top: 0;
  }
  img {
    padding: 0;
    margin-bottom: 20px;
  }
  .catalog__item-img {
    width: 100%;
  }
  .catalog__item-button {
    width: 100%;
    text-align: center;
  }
  .catalog__item-price {
    text-align: right;
    padding-bottom: 20px;
    span {
      font-size: 26px;
      font-weight: bold;
    }
  }
  .black__button {
    color: #FFFFFF;
    text-decoration: none;
    width: 100%;
  }
  .catalog__item-desc {
    line-height: 1.5em;
  }
  .files__item img {
    padding-right: 10px;
  }
  .catalog__item-char {
    ol {
      padding: 0;
    }
    ul li,
    ol li {
      list-style: none;
    }

    ol li {
      display: flex;
      justify-content: space-between;
    }

    li div:nth-child(2) {
      flex: 1 0;
      border-bottom: 1px dotted #000;
      height: 1em;
      margin: 0 .4em;
    }
    .value {
      font-weight: bold;
    }
  }
}

// Новости - деталка

.news__detail-img {
  padding: 0 !important;
}

.news__detail__item-date {
  font-size: 12px;
  color: #888888;
}

.news__detail__item-name {
  font-weight: bold;
  font-size: 18px;
  text-transform: none !important;
  a {
     text-decoration: none;
  }
}

.news__detail__item-desc {
  line-height: 1.5em;
  img {
    margin-bottom: 20px;
  }
}

.news__detail__item-sidebar {
  margin-bottom: 30px;
  .news__detail__item-img {
    padding: 0 !important;
    img {
      padding: 0 !important;
    }
  }
  .news__detail__item-text {
    background-color: #ffffff;
    font-size: 14px;
  }
}

.news__detail__item-sidebar .black__button {
  color: #ffffff;
  text-decoration: none;
  width: 100%;
}

// Media

#demo {
  height:100%;
  position:relative;
  overflow:hidden;
}

img.zoom {
  width: 100%;
  height: 200px;
  border-radius:5px;
  object-fit:cover;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
}

.transition {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}
.modal-header {

  border-bottom: none;
}
.modal-title {
  color:#000;
}
.modal-footer{
  display:none;
}


@media screen and (max-width: 1622px) {
  .goods__main .goods__item-name a {
    font-size: 14px;
  }
}


@media screen and (max-width: 1436px) {
  .mob_menu a {
    font-size: 14px !important;
  }
  .header__inner a {
    font-size: 11px;
  }
}

@media screen and (max-width: 1280px) {
  .actions__block-button {
    //width: 100% !important;
    //min-width: 100% !important;
    //margin-bottom: 20px;
  }
  .slider .slider__bottom-img {
    height: 50%;
  }
  .actions__block-title, .actions__block-button {
    width: 100%;
    min-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px;
  }
  .goods__item-name {
    line-height: 0.9em;
  }
  .goods .goods__item {
    margin-top: 20px;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
  }
  .goods__main .goods__item-name a {
    font-size: 14px;
  }
  .goods__main .goods__item {
    margin-top: 20px;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .goods .goods__item-name a {
    font-size: 14px;
  }
  .header ul li {
    margin-right: 6px;
  }
}


@media screen and (max-width: 1100px) {
  .catalog__item-card, .catalog__item-desc {
    width: 100%;
    min-width: 100%;
    flex: 0 0 100%
  }
  .catalog__item .catalog__item-button {
    margin-bottom: 50px;
  }
  .catalog__item h1 {
    text-align: center;
  }
}

@media screen and (max-width: 991px) {
  .header ul li {
    margin-right: 5px;
  }
  .goods .goods__item {
    margin-top: 20px;
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
  }
  .goods .goods__item-name a {
    font-size: 13px;
  }

  .goods__main .goods__item {
    margin-top: 20px;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%;
  }
  .nonvism {
    display: none;
  }
  .mob_menu {
    display: block !important;
  }
  .intro {
    background-size: cover;
  }
  .intro p {
    text-align: left;
    font-size: 20px;
  }
  .intro .intro__item-img {
    text-align: center;
    width: 35% !important;
    max-width: 35% !important;
  }
  .intro .intro__item-text {
    text-align: center;
    width: 65% !important;
    max-width: 65% !important;
  }
  .intro__item {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .bblock {
    background: #ffffff;
    display: block;
  }
}

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .slider .slider__bottom-item {
    text-align: center;
    margin-bottom: 0;
    width: 33%;
    max-width: 33%;
    flex: 0 0 33%;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .slider__bottom-item {
    .row {
      width: 100%;
      margin-bottom: 20px !important;
    }
  }
  .slider .slider__bottom-img {
    height: 100%;
    width: 50%;
    img {
      max-width: 120px;
      max-height: 120px;
    }
  }
  .slider .slider__bottom-text {
    width: 50%;
  }
  .slider .slider__button {
    font-size: 14px;
  }
  .actions__block-button {
    margin-bottom: 20px;
  }
  .slider .slider__bottom-title {
    font-weight: 700;
    font-size: 14px;
    a {
      color: #1a1a1a;
      //font-size: 16px;
    }
  }
  .slider .slider__bottom-desc a {
    color: #1a1a1a;
    font-size: 13px;
  }
  .slider h1 {
    font-size: 25px;
  }
  .intro .intro__item-text {
    margin-bottom: 20px;
  }
  .projects .col-md-five {
    width: 100%;
    margin-bottom: 20px;
  }
  .projects .projects__item-first h1 {
    font-size: 30px;
  }
  .projects__item .row {
    width: 100%;
  }
  .projects__item-first p {
    margin-bottom: 30px;
  }
  .projects__item-first p a {
    font-size: 18px;
  }
  .projects .projects__item-name {
    font-size: 20px;
  }
  .goods .goods__item-name a {
    font-size: 18px;
  }
  .goods .goods__item-text .goods__item-price {
    font-size: 20px;
  }
  .catalog__inner, .files, .filials, .page__title, .portfolio, .vcenter, .search-page {
    margin: 0 auto;
    width: 100%;
  }
  .catalog__item .files__item img {
    padding-right: 10px;
    float: left;
  }
  .page__title {
    padding: 0 15px;
  }
  .page__title h1 {
    font-size: 40px;
  }
  .files img {
    float: none;
  }
  .files .files__item {
    text-align: center;
  }
  video {
    height: 300px;
  }
  #map {
    height: 200px !important;
  }
  .goods .goods__item {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }
  .actions__block {
    flex: 0 0 100%;
    width: 100%;
    background-size: cover;
  }
}

/* свойства модального окна по умолчанию */
.modal {
  position: fixed; /* фиксированное положение */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); /* цвет фона */
  z-index: 1050;
  opacity: 0; /* по умолчанию модальное окно прозрачно */
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in; /* анимация перехода */
  pointer-events: none; /* элемент невидим для событий мыши */
}

/* при отображении модального окно */
.modal:target {
  opacity: 1;
  pointer-events: auto;
  overflow-y: auto;
}

/* ширина модального окна и его отступы от экрана */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto;
  }
}

/* свойства для блока, содержащего контент модального окна */
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, .2);
  border-radius: .3rem;
  outline: 0;
}

@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
  }
}

/* свойства для заголовка модального окна */
.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #eceeef;
}

.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
}

/* свойства для кнопки "Закрыть" */
.close {
  float: right;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  text-decoration: none;
}

/* свойства для кнопки "Закрыть" при нахождении её в фокусе или наведении */
.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .75;
}

/* свойства для блока, содержащего основное содержимое окна */
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 15px;
  overflow: auto;
}

.col-xs-five,
.col-sm-five,
.col-md-five,
.col-lg-five {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px;
}

.col-xs-five {
  width: 20%;
  float: left;
}

@media (min-width: 768px) {
  .col-sm-five {
    width: 20%;
    float: left;
  }
}

@media (min-width: 992px) {
  .col-md-five {
    width: 20%;
    float: left;
  }
}

@media (min-width: 1200px) {
  .col-lg-five {
    width: 20%;
    float: left;
  }
}

.nav {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  display: none;
}
.nav__item {
  display: block;
  height: 56px;
  font-weight: bold;
  color: #ffffff;
  font-size: 14px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #1a1a1a;
  a {
    color: #ffffff !important;
  }
}
.nav__item:not(:first-of-type) {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.nav__item:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}
.nav__item:hover {
  background-color: #aaa;
}
.nav__item:active {
  background-color: #aaa;
  color: #fff;
}
.nav-burger {
  display: block;
  width: 25px;
  height: 23px;
  cursor: pointer;
  color: transparent;
  font-size: 0;
  background: url("/img/icon-menu.svg") no-repeat;
  position: absolute;
  top: 27px;
  right: 20px;
  z-index: 1;
}
.nav-burger__checkbox {
  display: none;
}
.nav-burger__checkbox:checked + .nav-burger {
  background-image: url("/img/icon-cross.svg");
}
.nav-burger__checkbox:checked ~ .nav {
  display: block;
}

.mob_menu {
  display: none;
}

div.search-item h4 a {
  text-decoration: none;
  color: #1a1a1a;
}

div.modern-page-navigation {
  padding: .5em 0;
  text-align: center;
  margin-bottom: 20px;
}