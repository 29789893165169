@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Ubuntu:400,700");
.owl-carousel .owl-item img {
  display: inherit !important;
  width: auto !important; }

.goods .goods__item-text {
  padding-top: 5px; }

.row {
  margin: 0 !important;
  padding: 0 !important; }

.logo img {
  min-width: 80px; }

.text-uppercase {
  text-transform: uppercase !important; }

.black__button {
  padding: 15px 30px;
  background-color: #1a1a1a;
  color: #ffffff;
  font-weight: bold;
  text-align: center; }

.separator {
  padding: 15px; }

.breadcrumbs {
  margin: 20px auto;
  width: 80%; }
  .breadcrumbs a, .breadcrumbs span {
    color: #5d5b5b !important;
    font-size: 12px !important; }

.equal, .equal > .slider__bottom-item, .equal > .goods__item, .equal > .projects__item {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex; }

body {
  background-color: #ededed;
  padding: 0;
  margin: 0;
  font-family: 'Ubuntu', sans-serif; }

a {
  text-decoration: none; }

.header__inner {
  height: 80px; }
  .header__inner a {
    color: #ffffff;
    text-decoration: none;
    font-size: 14px; }

.header {
  background-color: #1a1a1a; }
  .header ul {
    margin: 0;
    padding: 4px;
    text-align: right; }
  .header ul li {
    display: inline;
    margin-right: 30px;
    padding: 3px; }
    @media screen and (max-width: 1280px) {
      .header ul li {
        margin-right: 10px; } }
    .header ul li a {
      text-transform: uppercase; }
  .header form {
    position: relative;
    width: 100%;
    margin: 0 auto;
    height: 42px; }
  .header input {
    color: #ffffff;
    height: 42px;
    width: 0;
    padding: 0 42px 0 15px;
    border: none;
    border-bottom: 2px solid transparent;
    outline: none;
    background: transparent;
    transition: 0.4s cubic-bezier(0, 0.8, 0, 1);
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2; }
  .header input:focus {
    width: 300px;
    z-index: 1;
    border-bottom: 2px solid #F9F0DA; }
  .header button {
    background: #1a1a1a;
    border: none;
    height: 42px;
    width: 42px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer; }
  .header button:before {
    content: "";
    background: url("/img/search.png") no-repeat center center;
    display: block;
    width: 30px;
    height: 30px; }

* {
  box-sizing: border-box; }

.footer {
  background-color: #1a1a1a;
  font-size: 13px; }
  .footer ul {
    margin: 0;
    padding: 4px;
    text-align: right; }
  .footer ul li {
    display: inline;
    margin-right: 17px;
    padding: 3px; }
    @media screen and (max-width: 1280px) {
      .footer ul li {
        margin-right: 10px; } }
    .footer ul li a {
      text-transform: uppercase; }
  .footer .footer__inner {
    height: 60px; }
    .footer .footer__inner a {
      color: #adadad;
      text-decoration: none;
      font-size: 13px; }
      @media screen and (max-width: 1280px) {
        .footer .footer__inner a {
          font-size: 10px; } }
  .footer p {
    color: #adadad; }

.slider {
  background-image: url("/img/slider.jpg");
  background-position: top center;
  background-size: cover;
  position: relative;
  height: 100%; }
  .slider h1 {
    font-size: 3vw;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase; }
  .slider .slider__title {
    padding: 5% 14%; }
  .slider .slider__button {
    padding: 15px 28px;
    background-color: #1a1a1a;
    color: #ffffff;
    font-weight: bold;
    text-align: center; }
  .slider .slider__block-button {
    text-align: center; }
  .slider .slider__bottom {
    margin-top: 10% !important; }
  .slider .slider__bottom-item {
    text-align: center;
    margin-bottom: 0; }
  .slider .slider__bottom-img {
    background-color: #1a1a1a;
    height: 60%;
    justify-content: center; }
    .slider .slider__bottom-img img {
      padding: 20px;
      max-width: 100%; }
  .slider .slider__bottom-text {
    background-color: #ffffff;
    min-height: 40%; }
    @media screen and (max-width: 1280px) {
      .slider .slider__bottom-text {
        padding-bottom: 30px; } }
  .slider .slider__bottom-title {
    font-weight: bold; }

.actions {
  margin-top: 10px; }
  .actions .actions__block {
    margin: 25px 15px 0px;
    padding: 100px 0px 0px 0px; }
  .actions .ab-1 {
    background-image: url("/img/action-1.png");
    background-position: bottom; }
  .actions .ab-2 {
    background-image: url("/img/action-2.png");
    background-position: center; }
  .actions .ab-3 {
    background-image: url("/img/action-3.png");
    background-position: center; }
  .actions .actions__title {
    color: #ffffff;
    font-weight: bold;
    background: #1a1a1a;
    padding: 4px; }
  .actions .actions__button {
    padding: 10px;
    background-color: #ffffff;
    font-weight: bold;
    color: #1a1a1a;
    white-space: nowrap; }
    @media screen and (max-width: 1280px) {
      .actions .actions__button {
        font-size: 13px; } }

.goods__main .goods__item {
  background: #ffffff; }

.goods .goods__item {
  margin-top: 20px; }
  .goods .goods__item .row {
    background: #ffffff; }

.goods .goods__item-name a {
  color: #ffffff; }
  @media screen and (max-width: 1622px) {
    .goods .goods__item-name a {
      font-size: 11px; } }

.goods .goods__item-img {
  background-color: #ffffff;
  text-align: center;
  padding: 20px 0;
  height: 200px; }
  .goods .goods__item-img img {
    max-width: 100%;
    padding: 10px; }

.goods .goods__item-text {
  background-color: #1a1a1a;
  color: #ffffff;
  height: 150px; }
  .goods .goods__item-text .goods__item-price {
    text-align: right;
    font-weight: bold; }

.intro {
  background-image: url("/img/intro.png");
  text-align: center;
  padding: 40px 10px;
  margin-top: 30px; }
  .intro .intro__item-img {
    margin-bottom: 20px; }
  .intro h1 {
    color: #ffffff;
    font-size: 50px;
    padding-bottom: 40px; }
  .intro p {
    color: #ffffff;
    margin: 0px 20px; }

.projects {
  margin-top: 30px;
  margin-bottom: 30px; }
  .projects .col-xs-five {
    width: 100%;
    margin-bottom: 20px; }
  .projects .projects__item {
    margin-bottom: 20px; }
  .projects .projects__item-img {
    background-position: center;
    background-size: cover;
    height: 200px;
    padding: 0; }
  .projects .projects__item-text {
    height: 10%;
    background-color: #ffffff;
    text-align: left;
    font-weight: bold; }
  .projects .projects__item-date {
    color: #888888;
    font-size: 10px; }
  .projects .projects__item-name {
    font-size: 14px; }
  .projects .projects__item-name a {
    color: #1a1a1a; }
  .projects .projects__item-desc {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.7em; }
  .projects .projects__item-first {
    background-color: #ffffff;
    text-align: center;
    margin-bottom: 20px; }
    .projects .projects__item-first h1 {
      font-size: 2vw; }
    .projects .projects__item-first a {
      font-size: 12px;
      padding: 7px 15px;
      border: 1px solid #1a1a1a;
      color: #1a1a1a; }

.order {
  margin-top: 70px;
  margin-bottom: 70px;
  text-align: center; }

.files, .portfolio, .page__title, .filials, .catalog__inner, .vcenter, .search-page {
  margin: 0 auto;
  width: 80%; }

img {
  max-width: 100%;
  max-height: 100%; }

.page__title {
  text-align: center;
  padding: 40px; }
  .page__title h1 {
    font-size: 53px;
    text-transform: uppercase; }
  .page__title h2 {
    font-size: 37px;
    text-transform: uppercase; }
  .page__title p {
    font-weight: bold;
    font-size: 20px; }

p.upper {
  font-weight: normal;
  font-size: 25px;
  text-align: left; }

p.upper__light {
  font-weight: normal;
  font-size: 17px;
  text-align: left; }

.text-center {
  text-align: center !important; }

.search-form form {
  position: relative;
  max-width: 500px;
  margin: 0 auto; }

.search-form input {
  width: 100%;
  height: 42px;
  padding-left: 10px;
  border: 1px solid #bbbbbb;
  border-radius: 0;
  outline: none;
  background: #ffffff;
  color: #9E9C9C; }

.search-form button {
  position: absolute;
  top: 0;
  right: 0px;
  width: 80px;
  height: 42px;
  border: none;
  background: #1a1a1a;
  border-radius: 00;
  cursor: pointer; }

.search-form button:before {
  content: "Найти";
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  width: 50px; }

.articles {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px; }
  .articles .articles__name {
    font-weight: bold; }
  .articles .articles__list {
    text-align: left; }

.nfound {
  text-align: center;
  font-size: 20vw;
  display: flex;
  height: 700px; }
  .nfound span, .nfound img {
    margin: auto; }
  .nfound img {
    max-width: 100%; }

.files {
  margin-bottom: 50px; }
  .files .files__item {
    padding: 30px; }
  .files img {
    float: left;
    padding-right: 20px; }
  .files a {
    text-decoration: underline;
    color: #1a1a1a; }

.filials .filials__item {
  margin-bottom: 30px; }
  .filials .filials__item .row {
    height: 100%;
    background: #ffffff; }
  .filials .filials__item .filials__item-top {
    background-color: #1a1a1a;
    color: #ffffff;
    padding: 10px;
    height: 20%; }
  .filials .filials__item .filials__item-bottom {
    background-color: #ffffff; }

.portfolio .projects__item {
  margin-bottom: 30px; }

.catalog .slider__title {
  padding: 2% 14%; }

.catalog .slider__bottom {
  margin-top: 0 !important; }

.catalog__block .goods__item {
  margin: 0 0 30px 0; }

.catalog__dop_item {
  background-color: #ffffff;
  margin: 10px; }
  .catalog__dop_item p {
    font-weight: bold; }

.catalog__item h1 {
  padding-top: 0;
  margin-top: 0; }

.catalog__item img {
  padding: 0;
  margin-bottom: 20px; }

.catalog__item .catalog__item-img {
  width: 100%; }

.catalog__item .catalog__item-button {
  width: 100%;
  text-align: center; }

.catalog__item .catalog__item-price {
  text-align: right;
  padding-bottom: 20px; }
  .catalog__item .catalog__item-price span {
    font-size: 26px;
    font-weight: bold; }

.catalog__item .black__button {
  color: #FFFFFF;
  text-decoration: none;
  width: 100%; }

.catalog__item .catalog__item-desc {
  line-height: 1.5em; }

.catalog__item .files__item img {
  padding-right: 10px; }

.catalog__item .catalog__item-char ol {
  padding: 0; }

.catalog__item .catalog__item-char ul li,
.catalog__item .catalog__item-char ol li {
  list-style: none; }

.catalog__item .catalog__item-char ol li {
  display: flex;
  justify-content: space-between; }

.catalog__item .catalog__item-char li div:nth-child(2) {
  flex: 1 0;
  border-bottom: 1px dotted #000;
  height: 1em;
  margin: 0 .4em; }

.catalog__item .catalog__item-char .value {
  font-weight: bold; }

.news__detail-img {
  padding: 0 !important; }

.news__detail__item-date {
  font-size: 12px;
  color: #888888; }

.news__detail__item-name {
  font-weight: bold;
  font-size: 18px;
  text-transform: none !important; }
  .news__detail__item-name a {
    text-decoration: none; }

.news__detail__item-desc {
  line-height: 1.5em; }
  .news__detail__item-desc img {
    margin-bottom: 20px; }

.news__detail__item-sidebar {
  margin-bottom: 30px; }
  .news__detail__item-sidebar .news__detail__item-img {
    padding: 0 !important; }
    .news__detail__item-sidebar .news__detail__item-img img {
      padding: 0 !important; }
  .news__detail__item-sidebar .news__detail__item-text {
    background-color: #ffffff;
    font-size: 14px; }

.news__detail__item-sidebar .black__button {
  color: #ffffff;
  text-decoration: none;
  width: 100%; }

#demo {
  height: 100%;
  position: relative;
  overflow: hidden; }

img.zoom {
  width: 100%;
  height: 200px;
  border-radius: 5px;
  object-fit: cover;
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out; }

.transition {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2); }

.modal-header {
  border-bottom: none; }

.modal-title {
  color: #000; }

.modal-footer {
  display: none; }

@media screen and (max-width: 1622px) {
  .goods__main .goods__item-name a {
    font-size: 14px; } }

@media screen and (max-width: 1436px) {
  .mob_menu a {
    font-size: 14px !important; }
  .header__inner a {
    font-size: 11px; } }

@media screen and (max-width: 1280px) {
  .slider .slider__bottom-img {
    height: 50%; }
  .actions__block-title, .actions__block-button {
    width: 100%;
    min-width: 100%;
    flex: 0 0 100%;
    margin-bottom: 20px; }
  .goods__item-name {
    line-height: 0.9em; }
  .goods .goods__item {
    margin-top: 20px;
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%; }
  .goods__main .goods__item-name a {
    font-size: 14px; }
  .goods__main .goods__item {
    margin-top: 20px;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .goods .goods__item-name a {
    font-size: 14px; }
  .header ul li {
    margin-right: 6px; } }

@media screen and (max-width: 1100px) {
  .catalog__item-card, .catalog__item-desc {
    width: 100%;
    min-width: 100%;
    flex: 0 0 100%; }
  .catalog__item .catalog__item-button {
    margin-bottom: 50px; }
  .catalog__item h1 {
    text-align: center; } }

@media screen and (max-width: 991px) {
  .header ul li {
    margin-right: 5px; }
  .goods .goods__item {
    margin-top: 20px;
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%; }
  .goods .goods__item-name a {
    font-size: 13px; }
  .goods__main .goods__item {
    margin-top: 20px;
    flex: 0 0 100%;
    width: 100%;
    max-width: 100%; }
  .nonvism {
    display: none; }
  .mob_menu {
    display: block !important; }
  .intro {
    background-size: cover; }
  .intro p {
    text-align: left;
    font-size: 20px; }
  .intro .intro__item-img {
    text-align: center;
    width: 35% !important;
    max-width: 35% !important; }
  .intro .intro__item-text {
    text-align: center;
    width: 65% !important;
    max-width: 65% !important; }
  .intro__item {
    width: 100%; } }

@media screen and (min-width: 768px) {
  .bblock {
    background: #ffffff;
    display: block; } }

@media screen and (max-width: 1200px) and (min-width: 768px) {
  .slider .slider__bottom-item {
    text-align: center;
    margin-bottom: 0;
    width: 33%;
    max-width: 33%;
    flex: 0 0 33%;
    margin-bottom: 50px; } }

@media screen and (max-width: 768px) {
  .slider__bottom-item .row {
    width: 100%;
    margin-bottom: 20px !important; }
  .slider .slider__bottom-img {
    height: 100%;
    width: 50%; }
    .slider .slider__bottom-img img {
      max-width: 120px;
      max-height: 120px; }
  .slider .slider__bottom-text {
    width: 50%; }
  .slider .slider__button {
    font-size: 14px; }
  .actions__block-button {
    margin-bottom: 20px; }
  .slider .slider__bottom-title {
    font-weight: 700;
    font-size: 14px; }
    .slider .slider__bottom-title a {
      color: #1a1a1a; }
  .slider .slider__bottom-desc a {
    color: #1a1a1a;
    font-size: 13px; }
  .slider h1 {
    font-size: 25px; }
  .intro .intro__item-text {
    margin-bottom: 20px; }
  .projects .col-md-five {
    width: 100%;
    margin-bottom: 20px; }
  .projects .projects__item-first h1 {
    font-size: 30px; }
  .projects__item .row {
    width: 100%; }
  .projects__item-first p {
    margin-bottom: 30px; }
  .projects__item-first p a {
    font-size: 18px; }
  .projects .projects__item-name {
    font-size: 20px; }
  .goods .goods__item-name a {
    font-size: 18px; }
  .goods .goods__item-text .goods__item-price {
    font-size: 20px; }
  .catalog__inner, .files, .filials, .page__title, .portfolio, .vcenter, .search-page {
    margin: 0 auto;
    width: 100%; }
  .catalog__item .files__item img {
    padding-right: 10px;
    float: left; }
  .page__title {
    padding: 0 15px; }
  .page__title h1 {
    font-size: 40px; }
  .files img {
    float: none; }
  .files .files__item {
    text-align: center; }
  video {
    height: 300px; }
  #map {
    height: 200px !important; }
  .goods .goods__item {
    margin-top: 20px;
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%; }
  .actions__block {
    flex: 0 0 100%;
    width: 100%;
    background-size: cover; } }

/* свойства модального окна по умолчанию */
.modal {
  position: fixed;
  /* фиксированное положение */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  /* цвет фона */
  z-index: 1050;
  opacity: 0;
  /* по умолчанию модальное окно прозрачно */
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  /* анимация перехода */
  pointer-events: none;
  /* элемент невидим для событий мыши */ }

/* при отображении модального окно */
.modal:target {
  opacity: 1;
  pointer-events: auto;
  overflow-y: auto; }

/* ширина модального окна и его отступы от экрана */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 30px auto; } }

/* свойства для блока, содержащего контент модального окна */
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: .3rem;
  outline: 0; }

@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); } }

/* свойства для заголовка модального окна */
.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #eceeef; }

.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500; }

/* свойства для кнопки "Закрыть" */
.close {
  float: right;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
  text-decoration: none; }

/* свойства для кнопки "Закрыть" при нахождении её в фокусе или наведении */
.close:focus, .close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .75; }

/* свойства для блока, содержащего основное содержимое окна */
.modal-body {
  position: relative;
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 15px;
  overflow: auto; }

.col-xs-five,
.col-sm-five,
.col-md-five,
.col-lg-five {
  position: relative;
  min-height: 1px;
  padding-right: 10px;
  padding-left: 10px; }

.col-xs-five {
  width: 20%;
  float: left; }

@media (min-width: 768px) {
  .col-sm-five {
    width: 20%;
    float: left; } }

@media (min-width: 992px) {
  .col-md-five {
    width: 20%;
    float: left; } }

@media (min-width: 1200px) {
  .col-lg-five {
    width: 20%;
    float: left; } }

.nav {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 100;
  display: none; }

.nav__item {
  display: block;
  height: 56px;
  font-weight: bold;
  color: #ffffff;
  font-size: 14px;
  line-height: 56px;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  background-color: #1a1a1a; }
  .nav__item a {
    color: #ffffff !important; }

.nav__item:not(:first-of-type) {
  border-top: 1px solid rgba(255, 255, 255, 0.2); }

.nav__item:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2); }

.nav__item:hover {
  background-color: #aaa; }

.nav__item:active {
  background-color: #aaa;
  color: #fff; }

.nav-burger {
  display: block;
  width: 25px;
  height: 23px;
  cursor: pointer;
  color: transparent;
  font-size: 0;
  background: url("/img/icon-menu.svg") no-repeat;
  position: absolute;
  top: 27px;
  right: 20px;
  z-index: 1; }

.nav-burger__checkbox {
  display: none; }

.nav-burger__checkbox:checked + .nav-burger {
  background-image: url("/img/icon-cross.svg"); }

.nav-burger__checkbox:checked ~ .nav {
  display: block; }

.mob_menu {
  display: none; }

div.search-item h4 a {
  text-decoration: none;
  color: #1a1a1a; }

div.modern-page-navigation {
  padding: .5em 0;
  text-align: center;
  margin-bottom: 20px; }

.link {
  color: blue;
  cursor: pointer; }

@keyframes cdp-in {
  from {
    transform: scale(1.5);
    opacity: 0; }
  to {
    transform: scale(1);
    opacity: 1; } }

.cdp {
  position: relative;
  text-align: center;
  padding: 20px 0;
  font-size: 0;
  z-index: 6;
  margin: 50px 0;
  animation: cdp-in 500ms ease both;
  animation-timeout: 200ms; }

.cdp_i {
  font-size: 14px;
  text-decoration: none;
  transition: background 250ms;
  display: inline-block;
  text-transform: uppercase;
  margin: 0 3px 6px;
  height: 38px;
  min-width: 38px;
  border-radius: 38px;
  border: 1px solid #fff;
  line-height: 38px;
  padding: 0;
  color: #fff;
  font-weight: 700;
  letter-spacing: .03em;
  display: none; }

.cdp_i:first-child, .cdp_i:last-child {
  padding: 0 16px;
  margin: 0 12px 6px; }

.cdp_i:last-child, .cdp_i:nth-child(2), .cdp_i:nth-last-child(2) {
  display: inline-block; }

.cdp_i:hover {
  background-color: #1a1a1a;
  color: #fff; }

.cdp:not([actpage="1"]) .cdp_i:nth-child(1) {
  display: inline-block; }

.cdp[actpage="1"] .cdp_i:nth-child(-1):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="1"] .cdp_i:nth-child(-1):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="1"] .cdp_i:nth-child(0):not(:first-child) {
  display: inline-block; }

.cdp[actpage="1"] .cdp_i:nth-child(1):not(:first-child) {
  display: inline-block; }

.cdp[actpage="1"] .cdp_i:nth-child(2) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="1"] .cdp_i:nth-child(2) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="1"] .cdp_i:nth-child(3):not(:last-child) {
  display: inline-block; }

.cdp[actpage="1"] .cdp_i:nth-child(4):not(:last-child) {
  display: inline-block; }

.cdp[actpage="1"] .cdp_i:nth-child(5):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="1"] .cdp_i:nth-child(5):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="2"] .cdp_i:nth-child(0):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="2"] .cdp_i:nth-child(0):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="2"] .cdp_i:nth-child(1):not(:first-child) {
  display: inline-block; }

.cdp[actpage="2"] .cdp_i:nth-child(2):not(:first-child) {
  display: inline-block; }

.cdp[actpage="2"] .cdp_i:nth-child(3) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="2"] .cdp_i:nth-child(3) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="2"] .cdp_i:nth-child(4):not(:last-child) {
  display: inline-block; }

.cdp[actpage="2"] .cdp_i:nth-child(5):not(:last-child) {
  display: inline-block; }

.cdp[actpage="2"] .cdp_i:nth-child(6):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="2"] .cdp_i:nth-child(6):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="3"] .cdp_i:nth-child(1):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="3"] .cdp_i:nth-child(1):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="3"] .cdp_i:nth-child(2):not(:first-child) {
  display: inline-block; }

.cdp[actpage="3"] .cdp_i:nth-child(3):not(:first-child) {
  display: inline-block; }

.cdp[actpage="3"] .cdp_i:nth-child(4) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="3"] .cdp_i:nth-child(4) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="3"] .cdp_i:nth-child(5):not(:last-child) {
  display: inline-block; }

.cdp[actpage="3"] .cdp_i:nth-child(6):not(:last-child) {
  display: inline-block; }

.cdp[actpage="3"] .cdp_i:nth-child(7):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="3"] .cdp_i:nth-child(7):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="4"] .cdp_i:nth-child(2):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="4"] .cdp_i:nth-child(2):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="4"] .cdp_i:nth-child(3):not(:first-child) {
  display: inline-block; }

.cdp[actpage="4"] .cdp_i:nth-child(4):not(:first-child) {
  display: inline-block; }

.cdp[actpage="4"] .cdp_i:nth-child(5) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="4"] .cdp_i:nth-child(5) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="4"] .cdp_i:nth-child(6):not(:last-child) {
  display: inline-block; }

.cdp[actpage="4"] .cdp_i:nth-child(7):not(:last-child) {
  display: inline-block; }

.cdp[actpage="4"] .cdp_i:nth-child(8):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="4"] .cdp_i:nth-child(8):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="5"] .cdp_i:nth-child(3):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="5"] .cdp_i:nth-child(3):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="5"] .cdp_i:nth-child(4):not(:first-child) {
  display: inline-block; }

.cdp[actpage="5"] .cdp_i:nth-child(5):not(:first-child) {
  display: inline-block; }

.cdp[actpage="5"] .cdp_i:nth-child(6) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="5"] .cdp_i:nth-child(6) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="5"] .cdp_i:nth-child(7):not(:last-child) {
  display: inline-block; }

.cdp[actpage="5"] .cdp_i:nth-child(8):not(:last-child) {
  display: inline-block; }

.cdp[actpage="5"] .cdp_i:nth-child(9):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="5"] .cdp_i:nth-child(9):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="6"] .cdp_i:nth-child(4):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="6"] .cdp_i:nth-child(4):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="6"] .cdp_i:nth-child(5):not(:first-child) {
  display: inline-block; }

.cdp[actpage="6"] .cdp_i:nth-child(6):not(:first-child) {
  display: inline-block; }

.cdp[actpage="6"] .cdp_i:nth-child(7) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="6"] .cdp_i:nth-child(7) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="6"] .cdp_i:nth-child(8):not(:last-child) {
  display: inline-block; }

.cdp[actpage="6"] .cdp_i:nth-child(9):not(:last-child) {
  display: inline-block; }

.cdp[actpage="6"] .cdp_i:nth-child(10):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="6"] .cdp_i:nth-child(10):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="7"] .cdp_i:nth-child(5):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="7"] .cdp_i:nth-child(5):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="7"] .cdp_i:nth-child(6):not(:first-child) {
  display: inline-block; }

.cdp[actpage="7"] .cdp_i:nth-child(7):not(:first-child) {
  display: inline-block; }

.cdp[actpage="7"] .cdp_i:nth-child(8) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="7"] .cdp_i:nth-child(8) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="7"] .cdp_i:nth-child(9):not(:last-child) {
  display: inline-block; }

.cdp[actpage="7"] .cdp_i:nth-child(10):not(:last-child) {
  display: inline-block; }

.cdp[actpage="7"] .cdp_i:nth-child(11):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="7"] .cdp_i:nth-child(11):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="8"] .cdp_i:nth-child(6):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="8"] .cdp_i:nth-child(6):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="8"] .cdp_i:nth-child(7):not(:first-child) {
  display: inline-block; }

.cdp[actpage="8"] .cdp_i:nth-child(8):not(:first-child) {
  display: inline-block; }

.cdp[actpage="8"] .cdp_i:nth-child(9) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="8"] .cdp_i:nth-child(9) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="8"] .cdp_i:nth-child(10):not(:last-child) {
  display: inline-block; }

.cdp[actpage="8"] .cdp_i:nth-child(11):not(:last-child) {
  display: inline-block; }

.cdp[actpage="8"] .cdp_i:nth-child(12):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="8"] .cdp_i:nth-child(12):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="9"] .cdp_i:nth-child(7):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="9"] .cdp_i:nth-child(7):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="9"] .cdp_i:nth-child(8):not(:first-child) {
  display: inline-block; }

.cdp[actpage="9"] .cdp_i:nth-child(9):not(:first-child) {
  display: inline-block; }

.cdp[actpage="9"] .cdp_i:nth-child(10) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="9"] .cdp_i:nth-child(10) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="9"] .cdp_i:nth-child(11):not(:last-child) {
  display: inline-block; }

.cdp[actpage="9"] .cdp_i:nth-child(12):not(:last-child) {
  display: inline-block; }

.cdp[actpage="9"] .cdp_i:nth-child(13):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="9"] .cdp_i:nth-child(13):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="10"] .cdp_i:nth-child(8):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="10"] .cdp_i:nth-child(8):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="10"] .cdp_i:nth-child(9):not(:first-child) {
  display: inline-block; }

.cdp[actpage="10"] .cdp_i:nth-child(10):not(:first-child) {
  display: inline-block; }

.cdp[actpage="10"] .cdp_i:nth-child(11) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="10"] .cdp_i:nth-child(11) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="10"] .cdp_i:nth-child(12):not(:last-child) {
  display: inline-block; }

.cdp[actpage="10"] .cdp_i:nth-child(13):not(:last-child) {
  display: inline-block; }

.cdp[actpage="10"] .cdp_i:nth-child(14):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="10"] .cdp_i:nth-child(14):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="11"] .cdp_i:nth-child(9):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="11"] .cdp_i:nth-child(9):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="11"] .cdp_i:nth-child(10):not(:first-child) {
  display: inline-block; }

.cdp[actpage="11"] .cdp_i:nth-child(11):not(:first-child) {
  display: inline-block; }

.cdp[actpage="11"] .cdp_i:nth-child(12) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="11"] .cdp_i:nth-child(12) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="11"] .cdp_i:nth-child(13):not(:last-child) {
  display: inline-block; }

.cdp[actpage="11"] .cdp_i:nth-child(14):not(:last-child) {
  display: inline-block; }

.cdp[actpage="11"] .cdp_i:nth-child(15):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="11"] .cdp_i:nth-child(15):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="12"] .cdp_i:nth-child(10):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="12"] .cdp_i:nth-child(10):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="12"] .cdp_i:nth-child(11):not(:first-child) {
  display: inline-block; }

.cdp[actpage="12"] .cdp_i:nth-child(12):not(:first-child) {
  display: inline-block; }

.cdp[actpage="12"] .cdp_i:nth-child(13) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="12"] .cdp_i:nth-child(13) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="12"] .cdp_i:nth-child(14):not(:last-child) {
  display: inline-block; }

.cdp[actpage="12"] .cdp_i:nth-child(15):not(:last-child) {
  display: inline-block; }

.cdp[actpage="12"] .cdp_i:nth-child(16):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="12"] .cdp_i:nth-child(16):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="13"] .cdp_i:nth-child(11):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="13"] .cdp_i:nth-child(11):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="13"] .cdp_i:nth-child(12):not(:first-child) {
  display: inline-block; }

.cdp[actpage="13"] .cdp_i:nth-child(13):not(:first-child) {
  display: inline-block; }

.cdp[actpage="13"] .cdp_i:nth-child(14) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="13"] .cdp_i:nth-child(14) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="13"] .cdp_i:nth-child(15):not(:last-child) {
  display: inline-block; }

.cdp[actpage="13"] .cdp_i:nth-child(16):not(:last-child) {
  display: inline-block; }

.cdp[actpage="13"] .cdp_i:nth-child(17):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="13"] .cdp_i:nth-child(17):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="14"] .cdp_i:nth-child(12):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="14"] .cdp_i:nth-child(12):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="14"] .cdp_i:nth-child(13):not(:first-child) {
  display: inline-block; }

.cdp[actpage="14"] .cdp_i:nth-child(14):not(:first-child) {
  display: inline-block; }

.cdp[actpage="14"] .cdp_i:nth-child(15) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="14"] .cdp_i:nth-child(15) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="14"] .cdp_i:nth-child(16):not(:last-child) {
  display: inline-block; }

.cdp[actpage="14"] .cdp_i:nth-child(17):not(:last-child) {
  display: inline-block; }

.cdp[actpage="14"] .cdp_i:nth-child(18):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="14"] .cdp_i:nth-child(18):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="15"] .cdp_i:nth-child(13):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="15"] .cdp_i:nth-child(13):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="15"] .cdp_i:nth-child(14):not(:first-child) {
  display: inline-block; }

.cdp[actpage="15"] .cdp_i:nth-child(15):not(:first-child) {
  display: inline-block; }

.cdp[actpage="15"] .cdp_i:nth-child(16) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="15"] .cdp_i:nth-child(16) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="15"] .cdp_i:nth-child(17):not(:last-child) {
  display: inline-block; }

.cdp[actpage="15"] .cdp_i:nth-child(18):not(:last-child) {
  display: inline-block; }

.cdp[actpage="15"] .cdp_i:nth-child(19):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="15"] .cdp_i:nth-child(19):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="16"] .cdp_i:nth-child(14):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="16"] .cdp_i:nth-child(14):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="16"] .cdp_i:nth-child(15):not(:first-child) {
  display: inline-block; }

.cdp[actpage="16"] .cdp_i:nth-child(16):not(:first-child) {
  display: inline-block; }

.cdp[actpage="16"] .cdp_i:nth-child(17) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="16"] .cdp_i:nth-child(17) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="16"] .cdp_i:nth-child(18):not(:last-child) {
  display: inline-block; }

.cdp[actpage="16"] .cdp_i:nth-child(19):not(:last-child) {
  display: inline-block; }

.cdp[actpage="16"] .cdp_i:nth-child(20):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="16"] .cdp_i:nth-child(20):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="17"] .cdp_i:nth-child(15):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="17"] .cdp_i:nth-child(15):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="17"] .cdp_i:nth-child(16):not(:first-child) {
  display: inline-block; }

.cdp[actpage="17"] .cdp_i:nth-child(17):not(:first-child) {
  display: inline-block; }

.cdp[actpage="17"] .cdp_i:nth-child(18) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="17"] .cdp_i:nth-child(18) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="17"] .cdp_i:nth-child(19):not(:last-child) {
  display: inline-block; }

.cdp[actpage="17"] .cdp_i:nth-child(20):not(:last-child) {
  display: inline-block; }

.cdp[actpage="17"] .cdp_i:nth-child(21):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="17"] .cdp_i:nth-child(21):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="18"] .cdp_i:nth-child(16):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="18"] .cdp_i:nth-child(16):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="18"] .cdp_i:nth-child(17):not(:first-child) {
  display: inline-block; }

.cdp[actpage="18"] .cdp_i:nth-child(18):not(:first-child) {
  display: inline-block; }

.cdp[actpage="18"] .cdp_i:nth-child(19) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="18"] .cdp_i:nth-child(19) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="18"] .cdp_i:nth-child(20):not(:last-child) {
  display: inline-block; }

.cdp[actpage="18"] .cdp_i:nth-child(21):not(:last-child) {
  display: inline-block; }

.cdp[actpage="18"] .cdp_i:nth-child(22):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="18"] .cdp_i:nth-child(22):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="19"] .cdp_i:nth-child(17):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="19"] .cdp_i:nth-child(17):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="19"] .cdp_i:nth-child(18):not(:first-child) {
  display: inline-block; }

.cdp[actpage="19"] .cdp_i:nth-child(19):not(:first-child) {
  display: inline-block; }

.cdp[actpage="19"] .cdp_i:nth-child(20) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="19"] .cdp_i:nth-child(20) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="19"] .cdp_i:nth-child(21):not(:last-child) {
  display: inline-block; }

.cdp[actpage="19"] .cdp_i:nth-child(22):not(:last-child) {
  display: inline-block; }

.cdp[actpage="19"] .cdp_i:nth-child(23):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="19"] .cdp_i:nth-child(23):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="20"] .cdp_i:nth-child(18):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="20"] .cdp_i:nth-child(18):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="20"] .cdp_i:nth-child(19):not(:first-child) {
  display: inline-block; }

.cdp[actpage="20"] .cdp_i:nth-child(20):not(:first-child) {
  display: inline-block; }

.cdp[actpage="20"] .cdp_i:nth-child(21) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="20"] .cdp_i:nth-child(21) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="20"] .cdp_i:nth-child(22):not(:last-child) {
  display: inline-block; }

.cdp[actpage="20"] .cdp_i:nth-child(23):not(:last-child) {
  display: inline-block; }

.cdp[actpage="20"] .cdp_i:nth-child(24):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="20"] .cdp_i:nth-child(24):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="21"] .cdp_i:nth-child(19):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="21"] .cdp_i:nth-child(19):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="21"] .cdp_i:nth-child(20):not(:first-child) {
  display: inline-block; }

.cdp[actpage="21"] .cdp_i:nth-child(21):not(:first-child) {
  display: inline-block; }

.cdp[actpage="21"] .cdp_i:nth-child(22) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="21"] .cdp_i:nth-child(22) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="21"] .cdp_i:nth-child(23):not(:last-child) {
  display: inline-block; }

.cdp[actpage="21"] .cdp_i:nth-child(24):not(:last-child) {
  display: inline-block; }

.cdp[actpage="21"] .cdp_i:nth-child(25):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="21"] .cdp_i:nth-child(25):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="22"] .cdp_i:nth-child(20):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="22"] .cdp_i:nth-child(20):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="22"] .cdp_i:nth-child(21):not(:first-child) {
  display: inline-block; }

.cdp[actpage="22"] .cdp_i:nth-child(22):not(:first-child) {
  display: inline-block; }

.cdp[actpage="22"] .cdp_i:nth-child(23) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="22"] .cdp_i:nth-child(23) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="22"] .cdp_i:nth-child(24):not(:last-child) {
  display: inline-block; }

.cdp[actpage="22"] .cdp_i:nth-child(25):not(:last-child) {
  display: inline-block; }

.cdp[actpage="22"] .cdp_i:nth-child(26):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="22"] .cdp_i:nth-child(26):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="23"] .cdp_i:nth-child(21):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="23"] .cdp_i:nth-child(21):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="23"] .cdp_i:nth-child(22):not(:first-child) {
  display: inline-block; }

.cdp[actpage="23"] .cdp_i:nth-child(23):not(:first-child) {
  display: inline-block; }

.cdp[actpage="23"] .cdp_i:nth-child(24) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="23"] .cdp_i:nth-child(24) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="23"] .cdp_i:nth-child(25):not(:last-child) {
  display: inline-block; }

.cdp[actpage="23"] .cdp_i:nth-child(26):not(:last-child) {
  display: inline-block; }

.cdp[actpage="23"] .cdp_i:nth-child(27):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="23"] .cdp_i:nth-child(27):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="24"] .cdp_i:nth-child(22):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="24"] .cdp_i:nth-child(22):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="24"] .cdp_i:nth-child(23):not(:first-child) {
  display: inline-block; }

.cdp[actpage="24"] .cdp_i:nth-child(24):not(:first-child) {
  display: inline-block; }

.cdp[actpage="24"] .cdp_i:nth-child(25) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="24"] .cdp_i:nth-child(25) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="24"] .cdp_i:nth-child(26):not(:last-child) {
  display: inline-block; }

.cdp[actpage="24"] .cdp_i:nth-child(27):not(:last-child) {
  display: inline-block; }

.cdp[actpage="24"] .cdp_i:nth-child(28):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="24"] .cdp_i:nth-child(28):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="25"] .cdp_i:nth-child(23):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="25"] .cdp_i:nth-child(23):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="25"] .cdp_i:nth-child(24):not(:first-child) {
  display: inline-block; }

.cdp[actpage="25"] .cdp_i:nth-child(25):not(:first-child) {
  display: inline-block; }

.cdp[actpage="25"] .cdp_i:nth-child(26) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="25"] .cdp_i:nth-child(26) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="25"] .cdp_i:nth-child(27):not(:last-child) {
  display: inline-block; }

.cdp[actpage="25"] .cdp_i:nth-child(28):not(:last-child) {
  display: inline-block; }

.cdp[actpage="25"] .cdp_i:nth-child(29):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="25"] .cdp_i:nth-child(29):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="26"] .cdp_i:nth-child(24):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="26"] .cdp_i:nth-child(24):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="26"] .cdp_i:nth-child(25):not(:first-child) {
  display: inline-block; }

.cdp[actpage="26"] .cdp_i:nth-child(26):not(:first-child) {
  display: inline-block; }

.cdp[actpage="26"] .cdp_i:nth-child(27) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="26"] .cdp_i:nth-child(27) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="26"] .cdp_i:nth-child(28):not(:last-child) {
  display: inline-block; }

.cdp[actpage="26"] .cdp_i:nth-child(29):not(:last-child) {
  display: inline-block; }

.cdp[actpage="26"] .cdp_i:nth-child(30):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="26"] .cdp_i:nth-child(30):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="27"] .cdp_i:nth-child(25):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="27"] .cdp_i:nth-child(25):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="27"] .cdp_i:nth-child(26):not(:first-child) {
  display: inline-block; }

.cdp[actpage="27"] .cdp_i:nth-child(27):not(:first-child) {
  display: inline-block; }

.cdp[actpage="27"] .cdp_i:nth-child(28) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="27"] .cdp_i:nth-child(28) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="27"] .cdp_i:nth-child(29):not(:last-child) {
  display: inline-block; }

.cdp[actpage="27"] .cdp_i:nth-child(30):not(:last-child) {
  display: inline-block; }

.cdp[actpage="27"] .cdp_i:nth-child(31):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="27"] .cdp_i:nth-child(31):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="28"] .cdp_i:nth-child(26):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="28"] .cdp_i:nth-child(26):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="28"] .cdp_i:nth-child(27):not(:first-child) {
  display: inline-block; }

.cdp[actpage="28"] .cdp_i:nth-child(28):not(:first-child) {
  display: inline-block; }

.cdp[actpage="28"] .cdp_i:nth-child(29) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="28"] .cdp_i:nth-child(29) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="28"] .cdp_i:nth-child(30):not(:last-child) {
  display: inline-block; }

.cdp[actpage="28"] .cdp_i:nth-child(31):not(:last-child) {
  display: inline-block; }

.cdp[actpage="28"] .cdp_i:nth-child(32):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="28"] .cdp_i:nth-child(32):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="29"] .cdp_i:nth-child(27):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="29"] .cdp_i:nth-child(27):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="29"] .cdp_i:nth-child(28):not(:first-child) {
  display: inline-block; }

.cdp[actpage="29"] .cdp_i:nth-child(29):not(:first-child) {
  display: inline-block; }

.cdp[actpage="29"] .cdp_i:nth-child(30) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="29"] .cdp_i:nth-child(30) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="29"] .cdp_i:nth-child(31):not(:last-child) {
  display: inline-block; }

.cdp[actpage="29"] .cdp_i:nth-child(32):not(:last-child) {
  display: inline-block; }

.cdp[actpage="29"] .cdp_i:nth-child(33):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="29"] .cdp_i:nth-child(33):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="30"] .cdp_i:nth-child(28):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="30"] .cdp_i:nth-child(28):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="30"] .cdp_i:nth-child(29):not(:first-child) {
  display: inline-block; }

.cdp[actpage="30"] .cdp_i:nth-child(30):not(:first-child) {
  display: inline-block; }

.cdp[actpage="30"] .cdp_i:nth-child(31) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="30"] .cdp_i:nth-child(31) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="30"] .cdp_i:nth-child(32):not(:last-child) {
  display: inline-block; }

.cdp[actpage="30"] .cdp_i:nth-child(33):not(:last-child) {
  display: inline-block; }

.cdp[actpage="30"] .cdp_i:nth-child(34):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="30"] .cdp_i:nth-child(34):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="31"] .cdp_i:nth-child(29):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="31"] .cdp_i:nth-child(29):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="31"] .cdp_i:nth-child(30):not(:first-child) {
  display: inline-block; }

.cdp[actpage="31"] .cdp_i:nth-child(31):not(:first-child) {
  display: inline-block; }

.cdp[actpage="31"] .cdp_i:nth-child(32) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="31"] .cdp_i:nth-child(32) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="31"] .cdp_i:nth-child(33):not(:last-child) {
  display: inline-block; }

.cdp[actpage="31"] .cdp_i:nth-child(34):not(:last-child) {
  display: inline-block; }

.cdp[actpage="31"] .cdp_i:nth-child(35):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="31"] .cdp_i:nth-child(35):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="32"] .cdp_i:nth-child(30):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="32"] .cdp_i:nth-child(30):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="32"] .cdp_i:nth-child(31):not(:first-child) {
  display: inline-block; }

.cdp[actpage="32"] .cdp_i:nth-child(32):not(:first-child) {
  display: inline-block; }

.cdp[actpage="32"] .cdp_i:nth-child(33) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="32"] .cdp_i:nth-child(33) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="32"] .cdp_i:nth-child(34):not(:last-child) {
  display: inline-block; }

.cdp[actpage="32"] .cdp_i:nth-child(35):not(:last-child) {
  display: inline-block; }

.cdp[actpage="32"] .cdp_i:nth-child(36):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="32"] .cdp_i:nth-child(36):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="33"] .cdp_i:nth-child(31):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="33"] .cdp_i:nth-child(31):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="33"] .cdp_i:nth-child(32):not(:first-child) {
  display: inline-block; }

.cdp[actpage="33"] .cdp_i:nth-child(33):not(:first-child) {
  display: inline-block; }

.cdp[actpage="33"] .cdp_i:nth-child(34) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="33"] .cdp_i:nth-child(34) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="33"] .cdp_i:nth-child(35):not(:last-child) {
  display: inline-block; }

.cdp[actpage="33"] .cdp_i:nth-child(36):not(:last-child) {
  display: inline-block; }

.cdp[actpage="33"] .cdp_i:nth-child(37):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="33"] .cdp_i:nth-child(37):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="34"] .cdp_i:nth-child(32):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="34"] .cdp_i:nth-child(32):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="34"] .cdp_i:nth-child(33):not(:first-child) {
  display: inline-block; }

.cdp[actpage="34"] .cdp_i:nth-child(34):not(:first-child) {
  display: inline-block; }

.cdp[actpage="34"] .cdp_i:nth-child(35) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="34"] .cdp_i:nth-child(35) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="34"] .cdp_i:nth-child(36):not(:last-child) {
  display: inline-block; }

.cdp[actpage="34"] .cdp_i:nth-child(37):not(:last-child) {
  display: inline-block; }

.cdp[actpage="34"] .cdp_i:nth-child(38):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="34"] .cdp_i:nth-child(38):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="35"] .cdp_i:nth-child(33):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="35"] .cdp_i:nth-child(33):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="35"] .cdp_i:nth-child(34):not(:first-child) {
  display: inline-block; }

.cdp[actpage="35"] .cdp_i:nth-child(35):not(:first-child) {
  display: inline-block; }

.cdp[actpage="35"] .cdp_i:nth-child(36) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="35"] .cdp_i:nth-child(36) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="35"] .cdp_i:nth-child(37):not(:last-child) {
  display: inline-block; }

.cdp[actpage="35"] .cdp_i:nth-child(38):not(:last-child) {
  display: inline-block; }

.cdp[actpage="35"] .cdp_i:nth-child(39):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="35"] .cdp_i:nth-child(39):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="36"] .cdp_i:nth-child(34):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="36"] .cdp_i:nth-child(34):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="36"] .cdp_i:nth-child(35):not(:first-child) {
  display: inline-block; }

.cdp[actpage="36"] .cdp_i:nth-child(36):not(:first-child) {
  display: inline-block; }

.cdp[actpage="36"] .cdp_i:nth-child(37) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="36"] .cdp_i:nth-child(37) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="36"] .cdp_i:nth-child(38):not(:last-child) {
  display: inline-block; }

.cdp[actpage="36"] .cdp_i:nth-child(39):not(:last-child) {
  display: inline-block; }

.cdp[actpage="36"] .cdp_i:nth-child(40):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="36"] .cdp_i:nth-child(40):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="37"] .cdp_i:nth-child(35):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="37"] .cdp_i:nth-child(35):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="37"] .cdp_i:nth-child(36):not(:first-child) {
  display: inline-block; }

.cdp[actpage="37"] .cdp_i:nth-child(37):not(:first-child) {
  display: inline-block; }

.cdp[actpage="37"] .cdp_i:nth-child(38) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="37"] .cdp_i:nth-child(38) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="37"] .cdp_i:nth-child(39):not(:last-child) {
  display: inline-block; }

.cdp[actpage="37"] .cdp_i:nth-child(40):not(:last-child) {
  display: inline-block; }

.cdp[actpage="37"] .cdp_i:nth-child(41):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="37"] .cdp_i:nth-child(41):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="38"] .cdp_i:nth-child(36):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="38"] .cdp_i:nth-child(36):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="38"] .cdp_i:nth-child(37):not(:first-child) {
  display: inline-block; }

.cdp[actpage="38"] .cdp_i:nth-child(38):not(:first-child) {
  display: inline-block; }

.cdp[actpage="38"] .cdp_i:nth-child(39) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="38"] .cdp_i:nth-child(39) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="38"] .cdp_i:nth-child(40):not(:last-child) {
  display: inline-block; }

.cdp[actpage="38"] .cdp_i:nth-child(41):not(:last-child) {
  display: inline-block; }

.cdp[actpage="38"] .cdp_i:nth-child(42):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="38"] .cdp_i:nth-child(42):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="39"] .cdp_i:nth-child(37):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="39"] .cdp_i:nth-child(37):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="39"] .cdp_i:nth-child(38):not(:first-child) {
  display: inline-block; }

.cdp[actpage="39"] .cdp_i:nth-child(39):not(:first-child) {
  display: inline-block; }

.cdp[actpage="39"] .cdp_i:nth-child(40) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="39"] .cdp_i:nth-child(40) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="39"] .cdp_i:nth-child(41):not(:last-child) {
  display: inline-block; }

.cdp[actpage="39"] .cdp_i:nth-child(42):not(:last-child) {
  display: inline-block; }

.cdp[actpage="39"] .cdp_i:nth-child(43):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="39"] .cdp_i:nth-child(43):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="40"] .cdp_i:nth-child(38):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="40"] .cdp_i:nth-child(38):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="40"] .cdp_i:nth-child(39):not(:first-child) {
  display: inline-block; }

.cdp[actpage="40"] .cdp_i:nth-child(40):not(:first-child) {
  display: inline-block; }

.cdp[actpage="40"] .cdp_i:nth-child(41) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="40"] .cdp_i:nth-child(41) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="40"] .cdp_i:nth-child(42):not(:last-child) {
  display: inline-block; }

.cdp[actpage="40"] .cdp_i:nth-child(43):not(:last-child) {
  display: inline-block; }

.cdp[actpage="40"] .cdp_i:nth-child(44):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="40"] .cdp_i:nth-child(44):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="41"] .cdp_i:nth-child(39):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="41"] .cdp_i:nth-child(39):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="41"] .cdp_i:nth-child(40):not(:first-child) {
  display: inline-block; }

.cdp[actpage="41"] .cdp_i:nth-child(41):not(:first-child) {
  display: inline-block; }

.cdp[actpage="41"] .cdp_i:nth-child(42) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="41"] .cdp_i:nth-child(42) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="41"] .cdp_i:nth-child(43):not(:last-child) {
  display: inline-block; }

.cdp[actpage="41"] .cdp_i:nth-child(44):not(:last-child) {
  display: inline-block; }

.cdp[actpage="41"] .cdp_i:nth-child(45):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="41"] .cdp_i:nth-child(45):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="42"] .cdp_i:nth-child(40):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="42"] .cdp_i:nth-child(40):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="42"] .cdp_i:nth-child(41):not(:first-child) {
  display: inline-block; }

.cdp[actpage="42"] .cdp_i:nth-child(42):not(:first-child) {
  display: inline-block; }

.cdp[actpage="42"] .cdp_i:nth-child(43) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="42"] .cdp_i:nth-child(43) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="42"] .cdp_i:nth-child(44):not(:last-child) {
  display: inline-block; }

.cdp[actpage="42"] .cdp_i:nth-child(45):not(:last-child) {
  display: inline-block; }

.cdp[actpage="42"] .cdp_i:nth-child(46):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="42"] .cdp_i:nth-child(46):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="43"] .cdp_i:nth-child(41):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="43"] .cdp_i:nth-child(41):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="43"] .cdp_i:nth-child(42):not(:first-child) {
  display: inline-block; }

.cdp[actpage="43"] .cdp_i:nth-child(43):not(:first-child) {
  display: inline-block; }

.cdp[actpage="43"] .cdp_i:nth-child(44) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="43"] .cdp_i:nth-child(44) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="43"] .cdp_i:nth-child(45):not(:last-child) {
  display: inline-block; }

.cdp[actpage="43"] .cdp_i:nth-child(46):not(:last-child) {
  display: inline-block; }

.cdp[actpage="43"] .cdp_i:nth-child(47):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="43"] .cdp_i:nth-child(47):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="44"] .cdp_i:nth-child(42):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="44"] .cdp_i:nth-child(42):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="44"] .cdp_i:nth-child(43):not(:first-child) {
  display: inline-block; }

.cdp[actpage="44"] .cdp_i:nth-child(44):not(:first-child) {
  display: inline-block; }

.cdp[actpage="44"] .cdp_i:nth-child(45) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="44"] .cdp_i:nth-child(45) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="44"] .cdp_i:nth-child(46):not(:last-child) {
  display: inline-block; }

.cdp[actpage="44"] .cdp_i:nth-child(47):not(:last-child) {
  display: inline-block; }

.cdp[actpage="44"] .cdp_i:nth-child(48):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="44"] .cdp_i:nth-child(48):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="45"] .cdp_i:nth-child(43):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="45"] .cdp_i:nth-child(43):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="45"] .cdp_i:nth-child(44):not(:first-child) {
  display: inline-block; }

.cdp[actpage="45"] .cdp_i:nth-child(45):not(:first-child) {
  display: inline-block; }

.cdp[actpage="45"] .cdp_i:nth-child(46) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="45"] .cdp_i:nth-child(46) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="45"] .cdp_i:nth-child(47):not(:last-child) {
  display: inline-block; }

.cdp[actpage="45"] .cdp_i:nth-child(48):not(:last-child) {
  display: inline-block; }

.cdp[actpage="45"] .cdp_i:nth-child(49):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="45"] .cdp_i:nth-child(49):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="46"] .cdp_i:nth-child(44):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="46"] .cdp_i:nth-child(44):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="46"] .cdp_i:nth-child(45):not(:first-child) {
  display: inline-block; }

.cdp[actpage="46"] .cdp_i:nth-child(46):not(:first-child) {
  display: inline-block; }

.cdp[actpage="46"] .cdp_i:nth-child(47) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="46"] .cdp_i:nth-child(47) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="46"] .cdp_i:nth-child(48):not(:last-child) {
  display: inline-block; }

.cdp[actpage="46"] .cdp_i:nth-child(49):not(:last-child) {
  display: inline-block; }

.cdp[actpage="46"] .cdp_i:nth-child(50):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="46"] .cdp_i:nth-child(50):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="47"] .cdp_i:nth-child(45):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="47"] .cdp_i:nth-child(45):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="47"] .cdp_i:nth-child(46):not(:first-child) {
  display: inline-block; }

.cdp[actpage="47"] .cdp_i:nth-child(47):not(:first-child) {
  display: inline-block; }

.cdp[actpage="47"] .cdp_i:nth-child(48) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="47"] .cdp_i:nth-child(48) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="47"] .cdp_i:nth-child(49):not(:last-child) {
  display: inline-block; }

.cdp[actpage="47"] .cdp_i:nth-child(50):not(:last-child) {
  display: inline-block; }

.cdp[actpage="47"] .cdp_i:nth-child(51):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="47"] .cdp_i:nth-child(51):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="48"] .cdp_i:nth-child(46):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="48"] .cdp_i:nth-child(46):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="48"] .cdp_i:nth-child(47):not(:first-child) {
  display: inline-block; }

.cdp[actpage="48"] .cdp_i:nth-child(48):not(:first-child) {
  display: inline-block; }

.cdp[actpage="48"] .cdp_i:nth-child(49) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="48"] .cdp_i:nth-child(49) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="48"] .cdp_i:nth-child(50):not(:last-child) {
  display: inline-block; }

.cdp[actpage="48"] .cdp_i:nth-child(51):not(:last-child) {
  display: inline-block; }

.cdp[actpage="48"] .cdp_i:nth-child(52):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="48"] .cdp_i:nth-child(52):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="49"] .cdp_i:nth-child(47):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="49"] .cdp_i:nth-child(47):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="49"] .cdp_i:nth-child(48):not(:first-child) {
  display: inline-block; }

.cdp[actpage="49"] .cdp_i:nth-child(49):not(:first-child) {
  display: inline-block; }

.cdp[actpage="49"] .cdp_i:nth-child(50) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="49"] .cdp_i:nth-child(50) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="49"] .cdp_i:nth-child(51):not(:last-child) {
  display: inline-block; }

.cdp[actpage="49"] .cdp_i:nth-child(52):not(:last-child) {
  display: inline-block; }

.cdp[actpage="49"] .cdp_i:nth-child(53):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="49"] .cdp_i:nth-child(53):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="50"] .cdp_i:nth-child(48):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="50"] .cdp_i:nth-child(48):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="50"] .cdp_i:nth-child(49):not(:first-child) {
  display: inline-block; }

.cdp[actpage="50"] .cdp_i:nth-child(50):not(:first-child) {
  display: inline-block; }

.cdp[actpage="50"] .cdp_i:nth-child(51) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="50"] .cdp_i:nth-child(51) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="50"] .cdp_i:nth-child(52):not(:last-child) {
  display: inline-block; }

.cdp[actpage="50"] .cdp_i:nth-child(53):not(:last-child) {
  display: inline-block; }

.cdp[actpage="50"] .cdp_i:nth-child(54):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="50"] .cdp_i:nth-child(54):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="51"] .cdp_i:nth-child(49):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="51"] .cdp_i:nth-child(49):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="51"] .cdp_i:nth-child(50):not(:first-child) {
  display: inline-block; }

.cdp[actpage="51"] .cdp_i:nth-child(51):not(:first-child) {
  display: inline-block; }

.cdp[actpage="51"] .cdp_i:nth-child(52) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="51"] .cdp_i:nth-child(52) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="51"] .cdp_i:nth-child(53):not(:last-child) {
  display: inline-block; }

.cdp[actpage="51"] .cdp_i:nth-child(54):not(:last-child) {
  display: inline-block; }

.cdp[actpage="51"] .cdp_i:nth-child(55):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="51"] .cdp_i:nth-child(55):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="52"] .cdp_i:nth-child(50):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="52"] .cdp_i:nth-child(50):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="52"] .cdp_i:nth-child(51):not(:first-child) {
  display: inline-block; }

.cdp[actpage="52"] .cdp_i:nth-child(52):not(:first-child) {
  display: inline-block; }

.cdp[actpage="52"] .cdp_i:nth-child(53) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="52"] .cdp_i:nth-child(53) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="52"] .cdp_i:nth-child(54):not(:last-child) {
  display: inline-block; }

.cdp[actpage="52"] .cdp_i:nth-child(55):not(:last-child) {
  display: inline-block; }

.cdp[actpage="52"] .cdp_i:nth-child(56):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="52"] .cdp_i:nth-child(56):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="53"] .cdp_i:nth-child(51):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="53"] .cdp_i:nth-child(51):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="53"] .cdp_i:nth-child(52):not(:first-child) {
  display: inline-block; }

.cdp[actpage="53"] .cdp_i:nth-child(53):not(:first-child) {
  display: inline-block; }

.cdp[actpage="53"] .cdp_i:nth-child(54) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="53"] .cdp_i:nth-child(54) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="53"] .cdp_i:nth-child(55):not(:last-child) {
  display: inline-block; }

.cdp[actpage="53"] .cdp_i:nth-child(56):not(:last-child) {
  display: inline-block; }

.cdp[actpage="53"] .cdp_i:nth-child(57):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="53"] .cdp_i:nth-child(57):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="54"] .cdp_i:nth-child(52):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="54"] .cdp_i:nth-child(52):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="54"] .cdp_i:nth-child(53):not(:first-child) {
  display: inline-block; }

.cdp[actpage="54"] .cdp_i:nth-child(54):not(:first-child) {
  display: inline-block; }

.cdp[actpage="54"] .cdp_i:nth-child(55) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="54"] .cdp_i:nth-child(55) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="54"] .cdp_i:nth-child(56):not(:last-child) {
  display: inline-block; }

.cdp[actpage="54"] .cdp_i:nth-child(57):not(:last-child) {
  display: inline-block; }

.cdp[actpage="54"] .cdp_i:nth-child(58):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="54"] .cdp_i:nth-child(58):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="55"] .cdp_i:nth-child(53):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="55"] .cdp_i:nth-child(53):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="55"] .cdp_i:nth-child(54):not(:first-child) {
  display: inline-block; }

.cdp[actpage="55"] .cdp_i:nth-child(55):not(:first-child) {
  display: inline-block; }

.cdp[actpage="55"] .cdp_i:nth-child(56) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="55"] .cdp_i:nth-child(56) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="55"] .cdp_i:nth-child(57):not(:last-child) {
  display: inline-block; }

.cdp[actpage="55"] .cdp_i:nth-child(58):not(:last-child) {
  display: inline-block; }

.cdp[actpage="55"] .cdp_i:nth-child(59):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="55"] .cdp_i:nth-child(59):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="56"] .cdp_i:nth-child(54):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="56"] .cdp_i:nth-child(54):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="56"] .cdp_i:nth-child(55):not(:first-child) {
  display: inline-block; }

.cdp[actpage="56"] .cdp_i:nth-child(56):not(:first-child) {
  display: inline-block; }

.cdp[actpage="56"] .cdp_i:nth-child(57) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="56"] .cdp_i:nth-child(57) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="56"] .cdp_i:nth-child(58):not(:last-child) {
  display: inline-block; }

.cdp[actpage="56"] .cdp_i:nth-child(59):not(:last-child) {
  display: inline-block; }

.cdp[actpage="56"] .cdp_i:nth-child(60):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="56"] .cdp_i:nth-child(60):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="57"] .cdp_i:nth-child(55):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="57"] .cdp_i:nth-child(55):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="57"] .cdp_i:nth-child(56):not(:first-child) {
  display: inline-block; }

.cdp[actpage="57"] .cdp_i:nth-child(57):not(:first-child) {
  display: inline-block; }

.cdp[actpage="57"] .cdp_i:nth-child(58) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="57"] .cdp_i:nth-child(58) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="57"] .cdp_i:nth-child(59):not(:last-child) {
  display: inline-block; }

.cdp[actpage="57"] .cdp_i:nth-child(60):not(:last-child) {
  display: inline-block; }

.cdp[actpage="57"] .cdp_i:nth-child(61):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="57"] .cdp_i:nth-child(61):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="58"] .cdp_i:nth-child(56):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="58"] .cdp_i:nth-child(56):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="58"] .cdp_i:nth-child(57):not(:first-child) {
  display: inline-block; }

.cdp[actpage="58"] .cdp_i:nth-child(58):not(:first-child) {
  display: inline-block; }

.cdp[actpage="58"] .cdp_i:nth-child(59) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="58"] .cdp_i:nth-child(59) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="58"] .cdp_i:nth-child(60):not(:last-child) {
  display: inline-block; }

.cdp[actpage="58"] .cdp_i:nth-child(61):not(:last-child) {
  display: inline-block; }

.cdp[actpage="58"] .cdp_i:nth-child(62):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="58"] .cdp_i:nth-child(62):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="59"] .cdp_i:nth-child(57):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="59"] .cdp_i:nth-child(57):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="59"] .cdp_i:nth-child(58):not(:first-child) {
  display: inline-block; }

.cdp[actpage="59"] .cdp_i:nth-child(59):not(:first-child) {
  display: inline-block; }

.cdp[actpage="59"] .cdp_i:nth-child(60) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="59"] .cdp_i:nth-child(60) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="59"] .cdp_i:nth-child(61):not(:last-child) {
  display: inline-block; }

.cdp[actpage="59"] .cdp_i:nth-child(62):not(:last-child) {
  display: inline-block; }

.cdp[actpage="59"] .cdp_i:nth-child(63):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="59"] .cdp_i:nth-child(63):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="60"] .cdp_i:nth-child(58):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="60"] .cdp_i:nth-child(58):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="60"] .cdp_i:nth-child(59):not(:first-child) {
  display: inline-block; }

.cdp[actpage="60"] .cdp_i:nth-child(60):not(:first-child) {
  display: inline-block; }

.cdp[actpage="60"] .cdp_i:nth-child(61) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="60"] .cdp_i:nth-child(61) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="60"] .cdp_i:nth-child(62):not(:last-child) {
  display: inline-block; }

.cdp[actpage="60"] .cdp_i:nth-child(63):not(:last-child) {
  display: inline-block; }

.cdp[actpage="60"] .cdp_i:nth-child(64):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="60"] .cdp_i:nth-child(64):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="61"] .cdp_i:nth-child(59):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="61"] .cdp_i:nth-child(59):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="61"] .cdp_i:nth-child(60):not(:first-child) {
  display: inline-block; }

.cdp[actpage="61"] .cdp_i:nth-child(61):not(:first-child) {
  display: inline-block; }

.cdp[actpage="61"] .cdp_i:nth-child(62) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="61"] .cdp_i:nth-child(62) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="61"] .cdp_i:nth-child(63):not(:last-child) {
  display: inline-block; }

.cdp[actpage="61"] .cdp_i:nth-child(64):not(:last-child) {
  display: inline-block; }

.cdp[actpage="61"] .cdp_i:nth-child(65):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="61"] .cdp_i:nth-child(65):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="62"] .cdp_i:nth-child(60):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="62"] .cdp_i:nth-child(60):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="62"] .cdp_i:nth-child(61):not(:first-child) {
  display: inline-block; }

.cdp[actpage="62"] .cdp_i:nth-child(62):not(:first-child) {
  display: inline-block; }

.cdp[actpage="62"] .cdp_i:nth-child(63) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="62"] .cdp_i:nth-child(63) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="62"] .cdp_i:nth-child(64):not(:last-child) {
  display: inline-block; }

.cdp[actpage="62"] .cdp_i:nth-child(65):not(:last-child) {
  display: inline-block; }

.cdp[actpage="62"] .cdp_i:nth-child(66):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="62"] .cdp_i:nth-child(66):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="63"] .cdp_i:nth-child(61):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="63"] .cdp_i:nth-child(61):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="63"] .cdp_i:nth-child(62):not(:first-child) {
  display: inline-block; }

.cdp[actpage="63"] .cdp_i:nth-child(63):not(:first-child) {
  display: inline-block; }

.cdp[actpage="63"] .cdp_i:nth-child(64) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="63"] .cdp_i:nth-child(64) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="63"] .cdp_i:nth-child(65):not(:last-child) {
  display: inline-block; }

.cdp[actpage="63"] .cdp_i:nth-child(66):not(:last-child) {
  display: inline-block; }

.cdp[actpage="63"] .cdp_i:nth-child(67):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="63"] .cdp_i:nth-child(67):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="64"] .cdp_i:nth-child(62):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="64"] .cdp_i:nth-child(62):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="64"] .cdp_i:nth-child(63):not(:first-child) {
  display: inline-block; }

.cdp[actpage="64"] .cdp_i:nth-child(64):not(:first-child) {
  display: inline-block; }

.cdp[actpage="64"] .cdp_i:nth-child(65) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="64"] .cdp_i:nth-child(65) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="64"] .cdp_i:nth-child(66):not(:last-child) {
  display: inline-block; }

.cdp[actpage="64"] .cdp_i:nth-child(67):not(:last-child) {
  display: inline-block; }

.cdp[actpage="64"] .cdp_i:nth-child(68):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="64"] .cdp_i:nth-child(68):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="65"] .cdp_i:nth-child(63):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="65"] .cdp_i:nth-child(63):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="65"] .cdp_i:nth-child(64):not(:first-child) {
  display: inline-block; }

.cdp[actpage="65"] .cdp_i:nth-child(65):not(:first-child) {
  display: inline-block; }

.cdp[actpage="65"] .cdp_i:nth-child(66) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="65"] .cdp_i:nth-child(66) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="65"] .cdp_i:nth-child(67):not(:last-child) {
  display: inline-block; }

.cdp[actpage="65"] .cdp_i:nth-child(68):not(:last-child) {
  display: inline-block; }

.cdp[actpage="65"] .cdp_i:nth-child(69):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="65"] .cdp_i:nth-child(69):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="66"] .cdp_i:nth-child(64):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="66"] .cdp_i:nth-child(64):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="66"] .cdp_i:nth-child(65):not(:first-child) {
  display: inline-block; }

.cdp[actpage="66"] .cdp_i:nth-child(66):not(:first-child) {
  display: inline-block; }

.cdp[actpage="66"] .cdp_i:nth-child(67) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="66"] .cdp_i:nth-child(67) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="66"] .cdp_i:nth-child(68):not(:last-child) {
  display: inline-block; }

.cdp[actpage="66"] .cdp_i:nth-child(69):not(:last-child) {
  display: inline-block; }

.cdp[actpage="66"] .cdp_i:nth-child(70):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="66"] .cdp_i:nth-child(70):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="67"] .cdp_i:nth-child(65):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="67"] .cdp_i:nth-child(65):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="67"] .cdp_i:nth-child(66):not(:first-child) {
  display: inline-block; }

.cdp[actpage="67"] .cdp_i:nth-child(67):not(:first-child) {
  display: inline-block; }

.cdp[actpage="67"] .cdp_i:nth-child(68) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="67"] .cdp_i:nth-child(68) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="67"] .cdp_i:nth-child(69):not(:last-child) {
  display: inline-block; }

.cdp[actpage="67"] .cdp_i:nth-child(70):not(:last-child) {
  display: inline-block; }

.cdp[actpage="67"] .cdp_i:nth-child(71):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="67"] .cdp_i:nth-child(71):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="68"] .cdp_i:nth-child(66):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="68"] .cdp_i:nth-child(66):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="68"] .cdp_i:nth-child(67):not(:first-child) {
  display: inline-block; }

.cdp[actpage="68"] .cdp_i:nth-child(68):not(:first-child) {
  display: inline-block; }

.cdp[actpage="68"] .cdp_i:nth-child(69) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="68"] .cdp_i:nth-child(69) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="68"] .cdp_i:nth-child(70):not(:last-child) {
  display: inline-block; }

.cdp[actpage="68"] .cdp_i:nth-child(71):not(:last-child) {
  display: inline-block; }

.cdp[actpage="68"] .cdp_i:nth-child(72):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="68"] .cdp_i:nth-child(72):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="69"] .cdp_i:nth-child(67):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="69"] .cdp_i:nth-child(67):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="69"] .cdp_i:nth-child(68):not(:first-child) {
  display: inline-block; }

.cdp[actpage="69"] .cdp_i:nth-child(69):not(:first-child) {
  display: inline-block; }

.cdp[actpage="69"] .cdp_i:nth-child(70) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="69"] .cdp_i:nth-child(70) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="69"] .cdp_i:nth-child(71):not(:last-child) {
  display: inline-block; }

.cdp[actpage="69"] .cdp_i:nth-child(72):not(:last-child) {
  display: inline-block; }

.cdp[actpage="69"] .cdp_i:nth-child(73):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="69"] .cdp_i:nth-child(73):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="70"] .cdp_i:nth-child(68):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="70"] .cdp_i:nth-child(68):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="70"] .cdp_i:nth-child(69):not(:first-child) {
  display: inline-block; }

.cdp[actpage="70"] .cdp_i:nth-child(70):not(:first-child) {
  display: inline-block; }

.cdp[actpage="70"] .cdp_i:nth-child(71) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="70"] .cdp_i:nth-child(71) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="70"] .cdp_i:nth-child(72):not(:last-child) {
  display: inline-block; }

.cdp[actpage="70"] .cdp_i:nth-child(73):not(:last-child) {
  display: inline-block; }

.cdp[actpage="70"] .cdp_i:nth-child(74):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="70"] .cdp_i:nth-child(74):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="71"] .cdp_i:nth-child(69):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="71"] .cdp_i:nth-child(69):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="71"] .cdp_i:nth-child(70):not(:first-child) {
  display: inline-block; }

.cdp[actpage="71"] .cdp_i:nth-child(71):not(:first-child) {
  display: inline-block; }

.cdp[actpage="71"] .cdp_i:nth-child(72) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="71"] .cdp_i:nth-child(72) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="71"] .cdp_i:nth-child(73):not(:last-child) {
  display: inline-block; }

.cdp[actpage="71"] .cdp_i:nth-child(74):not(:last-child) {
  display: inline-block; }

.cdp[actpage="71"] .cdp_i:nth-child(75):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="71"] .cdp_i:nth-child(75):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="72"] .cdp_i:nth-child(70):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="72"] .cdp_i:nth-child(70):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="72"] .cdp_i:nth-child(71):not(:first-child) {
  display: inline-block; }

.cdp[actpage="72"] .cdp_i:nth-child(72):not(:first-child) {
  display: inline-block; }

.cdp[actpage="72"] .cdp_i:nth-child(73) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="72"] .cdp_i:nth-child(73) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="72"] .cdp_i:nth-child(74):not(:last-child) {
  display: inline-block; }

.cdp[actpage="72"] .cdp_i:nth-child(75):not(:last-child) {
  display: inline-block; }

.cdp[actpage="72"] .cdp_i:nth-child(76):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="72"] .cdp_i:nth-child(76):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="73"] .cdp_i:nth-child(71):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="73"] .cdp_i:nth-child(71):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="73"] .cdp_i:nth-child(72):not(:first-child) {
  display: inline-block; }

.cdp[actpage="73"] .cdp_i:nth-child(73):not(:first-child) {
  display: inline-block; }

.cdp[actpage="73"] .cdp_i:nth-child(74) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="73"] .cdp_i:nth-child(74) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="73"] .cdp_i:nth-child(75):not(:last-child) {
  display: inline-block; }

.cdp[actpage="73"] .cdp_i:nth-child(76):not(:last-child) {
  display: inline-block; }

.cdp[actpage="73"] .cdp_i:nth-child(77):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="73"] .cdp_i:nth-child(77):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="74"] .cdp_i:nth-child(72):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="74"] .cdp_i:nth-child(72):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="74"] .cdp_i:nth-child(73):not(:first-child) {
  display: inline-block; }

.cdp[actpage="74"] .cdp_i:nth-child(74):not(:first-child) {
  display: inline-block; }

.cdp[actpage="74"] .cdp_i:nth-child(75) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="74"] .cdp_i:nth-child(75) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="74"] .cdp_i:nth-child(76):not(:last-child) {
  display: inline-block; }

.cdp[actpage="74"] .cdp_i:nth-child(77):not(:last-child) {
  display: inline-block; }

.cdp[actpage="74"] .cdp_i:nth-child(78):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="74"] .cdp_i:nth-child(78):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="75"] .cdp_i:nth-child(73):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="75"] .cdp_i:nth-child(73):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="75"] .cdp_i:nth-child(74):not(:first-child) {
  display: inline-block; }

.cdp[actpage="75"] .cdp_i:nth-child(75):not(:first-child) {
  display: inline-block; }

.cdp[actpage="75"] .cdp_i:nth-child(76) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="75"] .cdp_i:nth-child(76) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="75"] .cdp_i:nth-child(77):not(:last-child) {
  display: inline-block; }

.cdp[actpage="75"] .cdp_i:nth-child(78):not(:last-child) {
  display: inline-block; }

.cdp[actpage="75"] .cdp_i:nth-child(79):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="75"] .cdp_i:nth-child(79):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="76"] .cdp_i:nth-child(74):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="76"] .cdp_i:nth-child(74):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="76"] .cdp_i:nth-child(75):not(:first-child) {
  display: inline-block; }

.cdp[actpage="76"] .cdp_i:nth-child(76):not(:first-child) {
  display: inline-block; }

.cdp[actpage="76"] .cdp_i:nth-child(77) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="76"] .cdp_i:nth-child(77) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="76"] .cdp_i:nth-child(78):not(:last-child) {
  display: inline-block; }

.cdp[actpage="76"] .cdp_i:nth-child(79):not(:last-child) {
  display: inline-block; }

.cdp[actpage="76"] .cdp_i:nth-child(80):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="76"] .cdp_i:nth-child(80):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="77"] .cdp_i:nth-child(75):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="77"] .cdp_i:nth-child(75):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="77"] .cdp_i:nth-child(76):not(:first-child) {
  display: inline-block; }

.cdp[actpage="77"] .cdp_i:nth-child(77):not(:first-child) {
  display: inline-block; }

.cdp[actpage="77"] .cdp_i:nth-child(78) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="77"] .cdp_i:nth-child(78) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="77"] .cdp_i:nth-child(79):not(:last-child) {
  display: inline-block; }

.cdp[actpage="77"] .cdp_i:nth-child(80):not(:last-child) {
  display: inline-block; }

.cdp[actpage="77"] .cdp_i:nth-child(81):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="77"] .cdp_i:nth-child(81):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="78"] .cdp_i:nth-child(76):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="78"] .cdp_i:nth-child(76):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="78"] .cdp_i:nth-child(77):not(:first-child) {
  display: inline-block; }

.cdp[actpage="78"] .cdp_i:nth-child(78):not(:first-child) {
  display: inline-block; }

.cdp[actpage="78"] .cdp_i:nth-child(79) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="78"] .cdp_i:nth-child(79) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="78"] .cdp_i:nth-child(80):not(:last-child) {
  display: inline-block; }

.cdp[actpage="78"] .cdp_i:nth-child(81):not(:last-child) {
  display: inline-block; }

.cdp[actpage="78"] .cdp_i:nth-child(82):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="78"] .cdp_i:nth-child(82):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="79"] .cdp_i:nth-child(77):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="79"] .cdp_i:nth-child(77):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="79"] .cdp_i:nth-child(78):not(:first-child) {
  display: inline-block; }

.cdp[actpage="79"] .cdp_i:nth-child(79):not(:first-child) {
  display: inline-block; }

.cdp[actpage="79"] .cdp_i:nth-child(80) {
  background-color: #000;
  color: #fff;
  display: inline-block; }

.cdp[actpage="79"] .cdp_i:nth-child(80) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="79"] .cdp_i:nth-child(81):not(:last-child) {
  display: inline-block; }

.cdp[actpage="79"] .cdp_i:nth-child(82):not(:last-child) {
  display: inline-block; }

.cdp[actpage="79"] .cdp_i:nth-child(83):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="79"] .cdp_i:nth-child(83):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="80"] .cdp_i:nth-child(78):not(:first-child):not(:nth-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="80"] .cdp_i:nth-child(78):not(:first-child):not(:nth-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }

.cdp[actpage="80"] .cdp_i:nth-child(79):not(:first-child) {
  display: inline-block; }

.cdp[actpage="80"] .cdp_i:nth-child(80):not(:first-child) {
  display: inline-block; }

.cdp[actpage="80"] .cdp_i:nth-child(81) {
  background-color: #1a1a1a;
  color: #fff;
  display: inline-block; }

.cdp[actpage="80"] .cdp_i:nth-child(81) + .cdp_i:last-child {
  display: none !important; }

.cdp[actpage="80"] .cdp_i:nth-child(82):not(:last-child) {
  display: inline-block; }

.cdp[actpage="80"] .cdp_i:nth-child(83):not(:last-child) {
  display: inline-block; }

.cdp[actpage="80"] .cdp_i:nth-child(84):not(:last-child):not(:nth-last-child(2)) {
  display: inline-block;
  pointer-events: none;
  color: transparent;
  border-color: transparent;
  width: 50px; }

.cdp[actpage="80"] .cdp_i:nth-child(84):not(:last-child):not(:nth-last-child(2)):after {
  content: '...';
  color: #fff;
  font-size: 32px;
  margin-left: -6px; }
